import { TextField } from '@mui/material';
import {
  ChangeEvent,
  Dispatch,
  ForwardedRef,
  forwardRef,
  KeyboardEventHandler,
  RefObject,
  SetStateAction,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useDashboardRecentSearches } from 'entities/dashboard/api/use-dashboard-recent-searches/useDashboardRecentSearches';
import { EXPLORE_SEARCH_ID, MENU_ID, MINIMAL_SEARCH_LENGTH } from 'entities/dashboard/constants/dashboardSearch';
import { SearchResult } from 'entities/dashboard/types/dashboardSearch';
import { focusOnFirstListElement } from 'entities/dashboard/utils/focusOnFirstListElement';
import { useDebounce } from 'shared/hooks/use-debounce/useDebounce';
import { DashboardSearchIcon } from 'widgets/dashboard/dashboard-search/ui/DashboardSearchIcon';

type Props = {
  containerRef: RefObject<HTMLDivElement>;
  handleCloseResults: () => void;
  handleOpenResults: () => void;
  isOpen: boolean;
  onChange: (value: string) => void;
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  searchResults: SearchResult[];
  searchValue: string;
  isLoading: boolean;
  setSearchValue: Dispatch<SetStateAction<string>>;
  size?: 'medium' | 'small';
  searchResultsRef: RefObject<HTMLUListElement>;
};

export const DashboardSearchTextField = forwardRef(
  (
    {
      containerRef,
      handleCloseResults,
      handleOpenResults,
      isLoading,
      isOpen,
      onChange,
      onInputChange,
      searchResults,
      searchResultsRef,
      searchValue,
      setSearchValue,
      size,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { hasRecentSearches } = useDashboardRecentSearches();
    const { t } = useTranslation('explore');

    const areResultsAvailable = hasRecentSearches || searchResults.length > 0;

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        if (!isOpen && !(event.key === 'ArrowDown' || event.key === 'ArrowUp')) return;

        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            if (!isOpen && areResultsAvailable) {
              handleOpenResults();
            }

            if ((event.target as HTMLElement).id === EXPLORE_SEARCH_ID) {
              event.stopPropagation();
              focusOnFirstListElement(searchResultsRef.current);
            }
            break;
          case 'ArrowUp':
            event.preventDefault();
            if (!isOpen && areResultsAvailable) {
              handleOpenResults();
            }
            break;
          case 'ArrowLeft':
          case 'ArrowRight':
            event.stopPropagation();
            break;
          case 'Escape':
            if (!isOpen) return;
            handleCloseResults();
            break;
        }
      },
      [searchResultsRef, isOpen, areResultsAvailable, handleOpenResults, handleCloseResults],
    );

    const debouncedSearchCallback = useDebounce((value: string) => {
      onChange(value);
    });

    const handleInputChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchValue(value);
        if (value.length >= MINIMAL_SEARCH_LENGTH) {
          debouncedSearchCallback(value);
        } else {
          debouncedSearchCallback('');
        }

        onInputChange(event);
      },
      [debouncedSearchCallback, onInputChange, setSearchValue],
    );

    const handleReset = useCallback(() => {
      setSearchValue('');
      if (ref && 'current' in ref && ref.current) {
        ref.current.focus();
      }
    }, [ref, setSearchValue]);

    const handleOnclick = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        handleOpenResults();
      },
      [handleOpenResults],
    );

    return (
      <TextField
        size={'small'}
        placeholder={t('explore:input.placeholder')}
        fullWidth
        ref={containerRef}
        inputRef={ref}
        value={searchValue}
        onChange={handleInputChange}
        onFocus={handleOpenResults}
        onClick={handleOnclick}
        onKeyDown={handleKeyDown}
        sx={{
          backgroundColor: 'common.white',
          paddingRight: 0,
          height: size === 'small' ? 34 : 40,
          '& .MuiInputBase-root': {
            height: '100%',
          },
        }}
        inputProps={{
          'aria-controls': isOpen ? MENU_ID : undefined,
          'aria-haspopup': 'true',
          'aria-expanded': isOpen ? 'true' : undefined,
          id: EXPLORE_SEARCH_ID,
        }}
        autoComplete={'off'}
        InputProps={{
          endAdornment: (
            <DashboardSearchIcon searchValue={searchValue} isLoading={isLoading} handleReset={handleReset} />
          ),
          sx: { paddingRight: 0.5 },
        }}
      />
    );
  },
);

DashboardSearchTextField.displayName = 'SearchTextField';
