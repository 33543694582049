import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';

import { useDashboardRecentSearches } from 'entities/dashboard/api/use-dashboard-recent-searches/useDashboardRecentSearches';
import { SearchResult } from 'entities/dashboard/types/dashboardSearch';
import { SearchResultPaper } from 'entities/dashboard/ui/SearchResultPaper';
import { DashboardSearchResultsTabsFeature } from 'features/dashboard/dashboard-search-results-tabs/DashboardSearchResultsTabs.feature';

type Props = {
  onEscape: () => void;
  onSelect: (data: SearchResult) => void;
  width: number;
  searchResultsRef: RefObject<HTMLUListElement>;
  searchInputRef: RefObject<HTMLInputElement>;
};

export const DashboardLatestSearchesWidget = ({
  width,
  onSelect,
  onEscape,
  searchResultsRef,
  searchInputRef,
}: Props) => {
  const { recentSearches, hasRecentSearches, totals } = useDashboardRecentSearches();
  const { t } = useTranslation('explore');

  if (!hasRecentSearches) return null;

  return (
    <SearchResultPaper width={width}>
      <DashboardSearchResultsTabsFeature
        searchInputRef={searchInputRef}
        searchResultsRef={searchResultsRef}
        noResultsMessage={t('input.no-recent-searches')}
        onEscape={onEscape}
        onSelect={onSelect}
        searchResults={recentSearches}
        title={t('input.recent-searches')}
        totals={totals}
      />
    </SearchResultPaper>
  );
};
