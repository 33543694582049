import { Stack } from '@mui/material';
import { useCallback, useRef } from 'react';

import { useCompetitionsInfinityQuery } from 'api/competitions/use-competitions-infinity-query/useCompetitionsInfinityQuery';
import { useSetCompetitionsState } from 'entities/competitions/store/steps';
import { CompetitionRow } from 'features/competitions/competitions-step/ui/competition-row/CompetitionRow';
import { DashboardMenuItem } from 'shared/components/DashboardMenuItem';
import { DashboardMenuList } from 'shared/components/DashboardMenuList';
import Spinner from 'shared/components/spinner';
import { useIntersectionObserver } from 'shared/hooks/use-intersection-observer/useIntersectionObserver';
import { Competition } from 'shared/types/competition/competition';

export const CompetitionsStepFeature = () => {
  const { items, isLoading, fetchNextPage, hasNextPage } = useCompetitionsInfinityQuery();
  const lastCompetitionRef = useRef<HTMLLIElement | null>(null);
  const setCompetitionsState = useSetCompetitionsState();

  const handleObserver = useCallback(() => {
    if (!hasNextPage || isLoading) {
      return;
    }

    fetchNextPage();
  }, [fetchNextPage, hasNextPage, isLoading]);

  useIntersectionObserver(handleObserver, lastCompetitionRef);

  const handleGotToTeams = (competition: Competition) => {
    const defaultSeason = competition.seasons.toSorted((a, b) => b.year - a.year)[0];

    setCompetitionsState({
      step: 'teams',
      competition,
      season: defaultSeason,
    });
  };

  if (isLoading) {
    return (
      <Stack justifyContent={'center'} alignItems={'center'} pl={2}>
        <Spinner />
      </Stack>
    );
  }

  return (
    <>
      {items.length > 0 && (
        <DashboardMenuList>
          {items.map((competition, index) => (
            <DashboardMenuItem
              key={competition.id}
              ref={index === items.length - 1 ? lastCompetitionRef : null}
              onClick={() => handleGotToTeams(competition)}
            >
              <CompetitionRow countryCode={competition.country} name={competition.name} logoUrl={competition.logoUrl} />
            </DashboardMenuItem>
          ))}
        </DashboardMenuList>
      )}
    </>
  );
};
