import { useAtomValue } from 'jotai';

import { useFetchAccount } from 'api/user/use-fetch-account';
import { useFetchFeatureFlags } from 'api/user/use-fetch-feature-flags';
import { unregisterTrackedUser } from 'kognia/error-tracking/register-user';
import useIsLanguageDetectionReady from 'kognia/i18n/hooks/use-is-language-detection-ready';
import { currentClientIdAtom } from 'shared/contexts/app-state/atoms';
import { FeatureFlags, User } from 'shared/types/index';

export type UseAppDataType = {
  featureFlags: FeatureFlags | undefined;
  user: User | undefined;
};

interface UseAppDataInterface {
  data: UseAppDataType;
  isFetching: boolean;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  isPending: boolean;
  isUserAuthorized: boolean;
  isAppSettled: boolean;
}

export const useAppData = (): UseAppDataInterface => {
  const fetchFeatureFlags = useFetchFeatureFlags();
  const fetchAccount = useFetchAccount({
    onError: () => {
      unregisterTrackedUser();
      fetchFeatureFlags.setEnabled(false);
    },
    onSuccess: () => {
      fetchFeatureFlags.setEnabled(true);
    },
  });
  const isLanguageReady = useIsLanguageDetectionReady(fetchAccount.data);
  const currentClientId = useAtomValue(currentClientIdAtom);

  return {
    data: {
      user: fetchAccount.data,
      featureFlags: fetchFeatureFlags.data,
    },
    isError: fetchAccount.isError || fetchFeatureFlags.isError,
    isSuccess: fetchAccount.isSuccess && fetchFeatureFlags.isSuccess,
    isLoading: fetchAccount.isLoading || fetchFeatureFlags.isLoading,
    isFetching: fetchAccount.isFetching || fetchFeatureFlags.isFetching,
    isPending: fetchAccount.isPending || fetchFeatureFlags.isPending,
    isUserAuthorized: Boolean(fetchAccount.data),
    isAppSettled: Boolean(isLanguageReady && currentClientId && fetchAccount.data && fetchFeatureFlags.data),
  };
};
