import { Query, QueryClientProvider, QueryKey } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactNode, useEffect } from 'react';

import { queryClient } from 'api/config';
import { ACCOUNT_QUERY_REF } from 'api/user/use-fetch-account';
import { FEATURE_FLAGS_QUERY_KEY } from 'api/user/use-fetch-feature-flags';
import { useClientId } from 'shared/contexts/app-state/hooks/useClientId';

const findClientRelatedQueries = (query: Query<unknown, Error, unknown, QueryKey>) => {
  const omitKeys = [ACCOUNT_QUERY_REF, FEATURE_FLAGS_QUERY_KEY];
  return query.queryKey.some((key) => typeof key === 'string' && !omitKeys.includes(key));
};

export const ApiQueryProvider = ({ children }: { children?: ReactNode }) => {
  const { clientId } = useClientId();

  useEffect(() => {
    if (clientId) {
      queryClient.removeQueries({
        predicate: findClientRelatedQueries,
      });
      queryClient.invalidateQueries();
    }
  }, [clientId]);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      {children}
    </QueryClientProvider>
  );
};
