import { UseQueryResult } from '@tanstack/react-query';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { AccountUrl } from 'api/routes';
import { UseApiFetchOptions } from 'api/types';
import { Client, User, UserAuthority, UserRole } from 'shared/types';

export interface AccountAPIResponse {
  id: string;
  clients: Client[];
  email: string;
  firstName: string;
  lastName: string;
  phoneAreaCode?: string | null;
  phoneNumber?: string | null;
  role: UserRole;
  avatarUrl?: string;
  locale: string;
  authorities: string[];
}

type FetchAccountParams = {
  onError?: (error?: any) => void;
  onSuccess?: (user: User) => void;
  queryRef?: string[];
  options?: UseApiFetchOptions;
};

interface useFetchAccountInterface {
  (args?: FetchAccountParams): UseQueryResult<User>;
}

export const transformUser = (user: AccountAPIResponse): User => ({
  id: user.id,
  clients: user.clients,
  email: user.email,
  firstName: user.firstName,
  lastName: user.lastName,
  phoneAreaCode: user.phoneAreaCode ?? undefined,
  phoneNumber: user.phoneNumber ?? undefined,
  role: user.role as UserRole,
  avatarUrl: user.avatarUrl,
  locale: user.locale,
  authorities: user.authorities.map((authority) => authority as UserAuthority),
});

export const ACCOUNT_QUERY_REF = 'fetchAccount';

export const useFetchAccount: useFetchAccountInterface = (args) => {
  const queryRef = args?.queryRef || [ACCOUNT_QUERY_REF];

  return useFetchRequest<User>({
    onError: args?.onError,
    queryRef,
    url: AccountUrl,
    transformer: transformUser,
    onSuccess: args?.onSuccess,
    options: {
      retry: false,
      refetchOnWindowFocus: true,
      refetchInterval: 900000,
      ...args?.options,
    },
  });
};
