import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getOauth2LoginUrl } from 'api/routes';
import { ProtectedRouteInner } from 'shared/components/protected-route/ui/protected-route-inner/ProtectedRouteInner';
import { CONNECTION_QUERY_PARAM_NAME } from 'shared/constants/queryParams';
import { useAppData } from 'shared/contexts/app-state/hooks/useAppData';

type ProtectedRouteProps = {
  children: ReactNode;
};

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const location = useLocation();
  const { isFetching, isUserAuthorized, isAppSettled } = useAppData();

  const queryParams = new URLSearchParams(location.search);
  const connection = queryParams.get(CONNECTION_QUERY_PARAM_NAME);

  useEffect(() => {
    if (!isUserAuthorized && !isFetching) {
      window.location.href = getOauth2LoginUrl(location.pathname, connection);
    }
  }, [connection, isFetching, isUserAuthorized, location.pathname]);

  if (!isUserAuthorized || !isAppSettled) return null;

  return <ProtectedRouteInner>{children}</ProtectedRouteInner>;
};
