import { useUserPresets } from 'api/user-presets/use-user-presets';
import { TimelinePresets } from 'pages/tactical-analysis/api/use-tactical-analysis-data/types';
import { useSetPinScenarios } from 'pages/tactical-analysis/hooks/use-pin-scenarios';
import { useSetPresetPlayingMode } from 'pages/tactical-analysis/hooks/use-preset-playing-mode';
import { useSetTacticalAnalysisAppliedFilters } from 'pages/tactical-analysis/hooks/use-tactical-analysis-applied-filters';
import { useSetTacticalAnalysisSelectedTactics } from 'pages/tactical-analysis/hooks/use-tactical-analysis-selected-tactics';
import {
  useSetTimelineNoShowBallPossession,
  useSetTimelineShowBallPossession,
} from 'pages/tactical-analysis/hooks/use-timeline-ball-possession';
import { useSetTimelineHeadersWidth } from 'pages/tactical-analysis/hooks/use-timeline-headers-width';
import { useSetTimelineHeight } from 'pages/tactical-analysis/hooks/use-timeline-height';
import { useSetTimelineInitialTime } from 'pages/tactical-analysis/hooks/use-timeline-initial-time';
import { useSetTimelineTeamIdFocus } from 'pages/tactical-analysis/hooks/use-timeline-team-id-focus';
import { useSetTimelineZoomLevel } from 'pages/tactical-analysis/hooks/use-timeline-zoom-level';
import { PlayingMode, PlayingModes } from 'shared/components/video-player/types';
import {
  timelineAppliedFiltersPreset,
  timelineHeadersWidthPreset,
  timelineHeightPreset,
  timelinePinScenariosPreset,
  timelinePlayingModePreset,
  timelineSelectedTacticsPreset,
  timelineShowBallPossession,
  timelineShowNoBallPossession,
  timelineTeamIdFocus,
  timelineTimePreset,
  timelineZoomPreset,
} from 'shared/constants/user-presets/userPresetsTimeline';
import { UserPresetScope } from 'shared/types/user-preset/types';
import { getPreset } from 'shared/utils/user-presets/getPreset';

const fixPlayingMode = (playingMode: PlayingMode) => {
  if (playingMode.mode === PlayingModes.EPISODES) return PlayingModes.TACTICAL_CAMERA;
  if (playingMode.mode === PlayingModes.TACTICAL_CAMERA && playingMode.useEffectiveTime) return PlayingModes.EPISODES;

  return playingMode.mode;
};

export const useTacticalAnalysisPresets = (recordingId: string) => {
  const setPinScenarios = useSetPinScenarios();
  const setTimelineZoomLevel = useSetTimelineZoomLevel();
  const setTimelineHeight = useSetTimelineHeight();
  const setTimelineInitialTime = useSetTimelineInitialTime(recordingId);
  const setTimelineTeamIdFocus = useSetTimelineTeamIdFocus(recordingId);
  const setSelectedTactics = useSetTacticalAnalysisSelectedTactics(recordingId);
  const setPlayingMode = useSetPresetPlayingMode(recordingId);
  const setShowBallPossession = useSetTimelineShowBallPossession(recordingId);
  const setNoShowBallPossession = useSetTimelineNoShowBallPossession(recordingId);
  const setTacticalAnalysisAppliedFilters = useSetTacticalAnalysisAppliedFilters(recordingId);

  const setTimelineHeadersWidth = useSetTimelineHeadersWidth();

  const fetchTimelineRecordingPresets = useUserPresets<TimelinePresets>({
    prefix: 'team-centered-timeline',
    scope: UserPresetScope.timeline,
    ref: recordingId,
    onSuccess: (data) => {
      const initialTimePreset = getPreset(data, timelineTimePreset.key);
      const teamIdFocus = getPreset(data, timelineTeamIdFocus.key);
      const selectedTactics = getPreset(data, timelineSelectedTacticsPreset.key) ?? [];
      const selectedPlayingMode = getPreset(data, timelinePlayingModePreset.key);
      const showBallPossession = getPreset(data, timelineShowBallPossession.key);
      const showBallNoPossession = getPreset(data, timelineShowNoBallPossession.key);
      const appliedFilters = getPreset(data, timelineAppliedFiltersPreset.key);

      if (selectedPlayingMode) {
        setPlayingMode({
          ...selectedPlayingMode,
          mode: fixPlayingMode(selectedPlayingMode),
          showOverlays: selectedPlayingMode?.useEffectiveTime ?? true,
          useEffectiveTime: selectedPlayingMode?.useEffectiveTime ?? true,
        });
      }

      setSelectedTactics(selectedTactics);

      if (appliedFilters !== undefined) setTacticalAnalysisAppliedFilters(appliedFilters, false);
      if (showBallPossession !== undefined) setShowBallPossession(showBallPossession, false);
      if (showBallNoPossession !== undefined) setNoShowBallPossession(showBallNoPossession, false);
      if (teamIdFocus !== undefined) setTimelineTeamIdFocus(teamIdFocus, false);
      if (initialTimePreset !== undefined) setTimelineInitialTime(initialTimePreset);
    },
  });

  const fetchTimelineGlobalPresets = useUserPresets({
    scope: UserPresetScope.timeline,
    onSuccess: (data) => {
      const zoomPreset = getPreset(data, timelineZoomPreset.key);
      const heightPreset = getPreset(data, timelineHeightPreset.key);
      const headersWidth = getPreset(data, timelineHeadersWidthPreset.key);
      const pinScenarios = getPreset(data, timelinePinScenariosPreset.key);

      if (pinScenarios !== undefined) setPinScenarios(pinScenarios, false);
      if (headersWidth !== undefined) setTimelineHeadersWidth(headersWidth);
      if (zoomPreset !== undefined) setTimelineZoomLevel(zoomPreset);
      if (heightPreset !== undefined) setTimelineHeight(heightPreset);
    },
  });

  return {
    isSuccess: fetchTimelineGlobalPresets.isSuccess && fetchTimelineRecordingPresets.isSuccess,
    isLoading: fetchTimelineGlobalPresets.isLoading || fetchTimelineRecordingPresets.isLoading,
    isFetching: fetchTimelineGlobalPresets.isFetching || fetchTimelineRecordingPresets.isFetching,
  };
};
