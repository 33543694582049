import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { FixtureApi } from 'pages/backoffice/api/fixtures/types';
import { useInvalidateMetadataFixtures } from 'pages/backoffice/api/fixtures/use-metadata-fixtures';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';
import { MetadataFixture } from 'pages/backoffice/types/fixture';

interface RequestData {
  id: string;
  onSuccess?: () => void;
}

export const useDeleteRecording = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateMetadataFixtures = useInvalidateMetadataFixtures();
  const mutationRequest = useMutationRequest<FixtureApi, MetadataFixture, MetadataApiErrorResponse>({
    type: HTTPMethod.DELETE,
    successMessage: 'Recording deleted',
    errorMessage: (data) => metadataApiErrorParser(data, 'Error deleting recording'),
    onSuccess: invalidateMetadataFixtures,
  });

  const deleteRecording = ({ id, onSuccess }: RequestData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.RECORDINGS}/${id}`,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, deleteRecording };
};
