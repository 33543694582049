import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';

import { callBackendApi } from 'api/hooks/callBackendApi';
import { XMLDownloadUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';

export const XML_GROUP_BY = {
  TEAM: 'team',
  PLAYER: 'player',
};

export const useDownloadAnalysisXml = (
  recordingId: string,
  tacticalAnalysisId?: string,
  isEffectiveTime = false,
  groupBy: (typeof XML_GROUP_BY)[keyof typeof XML_GROUP_BY] = 'team',
) => {
  const downloadXml = () => (response: Blob, headers?: RawAxiosResponseHeaders | AxiosResponseHeaders) => {
    if (!headers) return;

    const blob = new Blob([response], {
      type: 'text/xml',
    });
    const blobURL = window.URL.createObjectURL(blob);

    const downloadLink = document.createElement('a');
    downloadLink.target = '_blank';
    downloadLink.href = blobURL;
    downloadLink.download = headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
    downloadLink.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );
    setTimeout(function () {
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  };

  return () =>
    callBackendApi<Blob>(
      XMLDownloadUrl(recordingId, tacticalAnalysisId, isEffectiveTime, groupBy),
      HTTPMethod.GET,
      downloadXml(),
    );
};
