import { zodResolver } from '@hookform/resolvers/zod';
import { Button, DialogActions, DialogContent, FormControl, Stack, FormHelperText } from '@mui/material';
import { IconKeypad } from 'kognia-ui/icons/IconKeypad';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useInvalidateMetadataFixture } from 'pages/backoffice/api/fixtures/use-metadata-fixture';
import { useInvalidateMetadataFixtures } from 'pages/backoffice/api/fixtures/use-metadata-fixtures';
import { useCreateMetadataRecording } from 'pages/backoffice/api/recordings/use-create-metadata-recording';
import { SelectClients } from 'pages/backoffice/components/select-clients';
import { FixtureRecordingsList } from 'pages/backoffice/pages/fixtures/components/fixture-recordings-list';
import {
  NewRecordingForm,
  NewRecordingFormFieldsNames,
  newRecordingSchema,
} from 'pages/backoffice/pages/fixtures/components/metadata-fixture-item/add-recording-id-dialog/EditRecordingIdsDialog';
import { generateRecordingId } from 'pages/backoffice/pages/fixtures/components/metadata-fixture-item/add-recording-id-dialog/utils';
import { MetadataFixture } from 'pages/backoffice/types/fixture';
import { DialogButtonContainer } from 'shared/components/dialog/dialog-button-container';
import { DialogHeader } from 'shared/components/dialog/dialog-header';
import { FormInputField } from 'shared/components/form/form-input-field';

type Props = {
  fixture: MetadataFixture;
  onSuccess: () => void;
  onCancel: (event: React.MouseEvent<HTMLElement>) => void;
};

const DEFAULT_RECORDING_TYPE = 'game';

export const EditRecordingIdsDialogForm = ({ fixture, onSuccess, onCancel }: Props) => {
  const { create } = useCreateMetadataRecording();
  const invalidateMetadataFixtures = useInvalidateMetadataFixtures();
  const invalidateMetadataFixture = useInvalidateMetadataFixture();

  const {
    register,
    formState: { isValid },
    handleSubmit: handleFormSubmit,
    control,
  } = useForm<NewRecordingForm>({
    resolver: zodResolver(newRecordingSchema),
    defaultValues: {},
  });

  const handleSubmit = useCallback(
    (data: NewRecordingForm) => {
      create({
        data: {
          recording_type: DEFAULT_RECORDING_TYPE,
          client_ids: data.clientIds,
          uuid: data.id,
          fixture_uuid: fixture.id,
        },
        onSuccess: () => {
          invalidateMetadataFixture(fixture.id);
          invalidateMetadataFixtures();
          onSuccess();
        },
      });
    },
    [onSuccess, invalidateMetadataFixture, fixture, create, invalidateMetadataFixtures],
  );

  const hasRecordingIds = fixture && fixture?.recordings?.length > 0;
  const defaultRecordingId = fixture
    ? generateRecordingId(fixture.date ?? '', fixture.homeTeam.abbreviation, fixture.awayTeam.abbreviation)
    : '';

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)}>
      <DialogHeader icon={<IconKeypad size='small' />}>Edit Recordings</DialogHeader>

      <DialogContent>
        <Stack gap={2}>
          <FormInputField
            label='Recording ID'
            id={NewRecordingFormFieldsNames.id}
            defaultChecked
            {...register(NewRecordingFormFieldsNames.id)}
            fullWidth
            placeholder={`yyyy-mm-dd-${fixture.homeTeam.abbreviation}-${fixture.awayTeam.abbreviation}`}
            defaultValue={hasRecordingIds ? '' : defaultRecordingId}
            size='small'
          />

          <FormControl fullWidth>
            <Controller
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <SelectClients clientIds={value ?? []} onChange={onChange} />
                  {error && <FormHelperText error>{error.message}</FormHelperText>}
                </>
              )}
              name={NewRecordingFormFieldsNames.clientIds}
              control={control}
            />
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <DialogButtonContainer>
          <Button variant='outlined' color='secondary' onClick={onCancel}>
            <span>Cancel</span>
          </Button>
        </DialogButtonContainer>
        <DialogButtonContainer>
          <Button type={'submit'} variant='contained' color='primary' disabled={!isValid}>
            <span>Add</span>
          </Button>
        </DialogButtonContainer>
      </DialogActions>
      <DialogContent>
        {fixture && fixture.recordings.length > 0 ? (
          <FixtureRecordingsList fixtureId={fixture.id} recordings={fixture.recordings} />
        ) : null}
      </DialogContent>
    </form>
  );
};
