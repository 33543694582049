import React, { useRef } from 'react';

import { EffectiveTimeSeparators } from 'pages/tactical-analysis/components/timeline/effective-time-separators';
import { FilterHighlights } from 'pages/tactical-analysis/components/timeline/filter-highlights';
import { PlayHead } from 'pages/tactical-analysis/components/timeline/play-head';
import { TimeRuler } from 'pages/tactical-analysis/components/timeline/time-ruler';
import {
  TimelineControls,
  TimelineControlsWrapper,
} from 'pages/tactical-analysis/components/timeline/timeline-controls';
import { EpisodesClips } from 'pages/tactical-analysis/components/timeline/timeline-table/components/episodes-clips';
import { EpisodesRow } from 'pages/tactical-analysis/components/timeline/timeline-table/components/episodes-row';
import { FiltersContentRow } from 'pages/tactical-analysis/components/timeline/timeline-table/components/filters-content-row';
import { FiltersHeaderRow } from 'pages/tactical-analysis/components/timeline/timeline-table/components/filters-headers-row';
import { RowGroupsList } from 'pages/tactical-analysis/components/timeline/timeline-table/components/row-groups-list';
import { RowHeadersContent } from 'pages/tactical-analysis/components/timeline/timeline-table/components/row-headers-content';
import { RowsContent } from 'pages/tactical-analysis/components/timeline/timeline-table/components/rows-content';
import { RowsContentContainer } from 'pages/tactical-analysis/components/timeline/timeline-table/components/rows-content-container';
import { RowsContentInner } from 'pages/tactical-analysis/components/timeline/timeline-table/components/rows-content-inner';
import { RowsHeaders } from 'pages/tactical-analysis/components/timeline/timeline-table/components/rows-headers';
import { ScenariosRowGroupContent } from 'pages/tactical-analysis/components/timeline/timeline-table/components/scenarios-row-group-content';
import { ScenariosRowGroupHeader } from 'pages/tactical-analysis/components/timeline/timeline-table/components/scenarios-row-group-header';
import { TimelineContentHeaderContainer } from 'pages/tactical-analysis/components/timeline/timeline-table/components/timeline-content-header-container';
import { TimelineCssVariables } from 'pages/tactical-analysis/components/timeline/timeline-table/components/timeline-css-variables';
import { TimelineIsReadyWrapper } from 'pages/tactical-analysis/components/timeline/timeline-table/components/timeline-is-ready-wrapper/TimelineIsReadyWrapper';
import { TimelineTableWrapper } from 'pages/tactical-analysis/components/timeline/timeline-table/components/timeline-table-wrapper';
import { useSyncHeight } from 'pages/tactical-analysis/components/timeline/timeline-table/hooks/use-sync-height';
import { useSyncRowContent } from 'pages/tactical-analysis/components/timeline/timeline-table/hooks/use-sync-row-content';
import { useTimelineHeadersWidth } from 'pages/tactical-analysis/hooks/use-timeline-headers-width';
import { useTimelineTableData } from 'pages/tactical-analysis/hooks/use-timeline-table-data';
import { useTimelineWheelZoom } from 'pages/tactical-analysis/hooks/use-timeline-wheel-zoom';
import { GroupedTags } from 'shared/types/tagging-events/types';

interface Props {
  recordingId: string;
  tags: GroupedTags;
}

export const TimelineTable = ({ recordingId }: Props) => {
  const containerDiv = useRef<HTMLDivElement>(null);
  const episodesRowRef = useRef<HTMLDivElement>(null);
  const headerContentRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const playHeadContainerRef = useRef<HTMLDivElement>(null);
  const rulerContainerRef = useRef<HTMLDivElement>(null);
  const visibleContentRef = useRef<HTMLDivElement>(null);

  useTimelineWheelZoom(visibleContentRef);
  useSyncHeight({ masterDiv: visibleContentRef, syncDiv: playHeadContainerRef });
  useSyncRowContent({
    recordingId,
    visibleContentRef,
    headerRef,
  });

  const initialHeadersWidth = useTimelineHeadersWidth();
  const timelineTableData = useTimelineTableData(recordingId);
  return (
    <TimelineIsReadyWrapper recordingId={recordingId}>
      <TimelineTableWrapper ref={containerDiv} recordingId={recordingId}>
        <TimelineCssVariables recordingId={recordingId} />

        <RowsHeaders
          recordingId={recordingId}
          ref={headerRef}
          headerRef={headerRef}
          visibleContentRef={visibleContentRef}
          style={{ width: `${initialHeadersWidth}px` }}
        >
          <RowHeadersContent ref={headerContentRef}>
            <TimelineControlsWrapper
              scenarios={
                <ScenariosRowGroupHeader
                  key={'scenarios-row-group'}
                  recordingId={recordingId}
                  rowGroup={timelineTableData.scenariosRowGroup}
                />
              }
              recordingId={recordingId}
            >
              <TimelineControls recordingId={recordingId} />
              <FiltersHeaderRow recordingId={recordingId} />
            </TimelineControlsWrapper>
            <RowGroupsList recordingId={recordingId} rowGroups={timelineTableData.rowGroups} />
          </RowHeadersContent>
        </RowsHeaders>

        <RowsContent ref={visibleContentRef}>
          <RowsContentInner>
            <RowsContentContainer
              recordingId={recordingId}
              stickComponents={
                <>
                  <TimeRuler
                    ref={rulerContainerRef}
                    recordingId={recordingId}
                    episodesClips={timelineTableData.episodesRow.clips}
                  />

                  <EpisodesRow ref={episodesRowRef} recordingId={recordingId}>
                    <EpisodesClips recordingId={recordingId} clips={timelineTableData.episodesRow.clips} />
                  </EpisodesRow>

                  <TimelineContentHeaderContainer ref={playHeadContainerRef}>
                    <FilterHighlights recordingId={recordingId} />
                    <EffectiveTimeSeparators episodesClips={timelineTableData.episodesRow.clips} />
                    <PlayHead
                      recordingId={recordingId}
                      headerContentRef={headerContentRef}
                      visibleContentRef={visibleContentRef}
                    />
                  </TimelineContentHeaderContainer>
                  <FiltersContentRow recordingId={recordingId} />
                </>
              }
              scenariosRow={
                <ScenariosRowGroupContent
                  key={'scenarios-content'}
                  recordingId={recordingId}
                  rowGroup={timelineTableData.scenariosRowGroup}
                />
              }
              contentRowGroups={timelineTableData.rowGroups}
            />
          </RowsContentInner>
        </RowsContent>
      </TimelineTableWrapper>
    </TimelineIsReadyWrapper>
  );
};
