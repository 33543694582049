import { SnackbarProvider } from 'notistack';
import React from 'react';
import { RecoilRoot } from 'recoil';

import { ApiQueryProvider } from 'api/context';
import { AppStateProvider } from 'shared/contexts/app-state/AppStateProvider';

const AppContext = ({ children }: any) => {
  return (
    <ApiQueryProvider>
      <RecoilRoot>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <AppStateProvider>{children}</AppStateProvider>
        </SnackbarProvider>
      </RecoilRoot>
    </ApiQueryProvider>
  );
};

export default AppContext;
