import { Stack, Typography } from '@mui/material';
import { Colors, fontWeight } from 'kognia-ui';
import { TeamAvatar } from 'kognia-ui/components/avatar/TeamAvatar';
import { IconDelete } from 'kognia-ui/icons/IconDelete';
import { IconEdit } from 'kognia-ui/icons/IconEdit';
import { IconEye } from 'kognia-ui/icons/IconEye';
import React, { useCallback, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import { useDeleteMetadataFixture } from 'pages/backoffice/api/fixtures/use-delete-metadata-fixture';
import { EditRecordingIdsDialog } from 'pages/backoffice/pages/fixtures/components/metadata-fixture-item/add-recording-id-dialog/EditRecordingIdsDialog';
import { FixturesListItem } from 'pages/backoffice/pages/fixtures/components/metadata-fixture-item/styled';
import { TacticalAnalysesDialog } from 'pages/backoffice/pages/fixtures/components/metadata-fixture-item/tactical-analyses-dialog/TacticalAnalysesDialog';
import { MetadataFixture } from 'pages/backoffice/types/fixture';
import ConfirmDialog from 'shared/components/confirm-dialog';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';
import { useDates } from 'shared/hooks/use-dates';

interface Props {
  fixture: MetadataFixture;
}

export const FixtureItem = ({ fixture }: Props) => {
  const { dateToString } = useDates();
  const navigate = useNavigate();
  const [isRecordingDialogOpen, setIsRecordingDialogOpen] = useState<boolean>(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState<boolean>(false);
  const [isTacticalAnalysesDialogOpen, setIsTacticalAnalysesDialogOpen] = useState<boolean>(false);
  const { deleteMetadataFixture } = useDeleteMetadataFixture();

  const handleDeleteFixture = useCallback(() => {
    deleteMetadataFixture({ id: fixture.id });
  }, [fixture.id, deleteMetadataFixture]);

  const options: MenuListOption[] = [
    {
      displayText: 'View fixture details',
      icon: <IconEye size='small' color='secondary' />,
      onClick: () => navigate(generatePath(routes.BACKOFFICE_FIXTURE_SUMMARY, { id: fixture.id })),
    },
    {
      displayText: 'Edit fixture',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: () => navigate(generatePath(routes.BACKOFFICE_EDIT_FIXTURE_FORM, { id: fixture.id })),
    },
    {
      displayText: 'Edit recordings',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: () => setIsRecordingDialogOpen(true),
    },
    {
      displayText: 'Tactical analyses',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: () => setIsTacticalAnalysesDialogOpen(true),
    },
    {
      displayText: 'Delete',
      icon: <IconDelete size='small' color='secondary' />,
      onClick: () => setIsConfirmDeleteDialogOpen(true),
      isHidden: fixture.recordings.length > 0,
    },
  ];

  const hasRecordings = fixture.recordings.length > 0;

  return (
    <>
      <FixturesListItem key={fixture.id} sx={{ background: hasRecordings ? 'transparent' : Colors.athens }}>
        <Stack gap={2}>
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <TeamAvatar src={fixture.homeTeam.logoUrl} name={fixture.homeTeam.name} />
            <Typography fontWeight={fontWeight['500']}>{fixture.homeTeamScore}</Typography>
            <Typography fontWeight={fontWeight['500']}>-</Typography>
            <Typography fontWeight={fontWeight['500']}>{fixture.awayTeamScore}</Typography>
            <TeamAvatar src={fixture.awayTeam.logoUrl} name={fixture.awayTeam.name} />
          </Stack>
          {!hasRecordings ? (
            <Stack alignItems={'center'} direction={'row'} gap={1} sx={{ color: Colors.storm }}>
              No recording id
            </Stack>
          ) : null}
        </Stack>
        <Stack>
          <Typography fontWeight={fontWeight['500']} textTransform={'uppercase'}>
            {fixture.homeTeam.abbreviation} - {fixture.awayTeam.abbreviation}
          </Typography>
          <Typography color={Colors.storm}>{fixture.season?.competition?.name}</Typography>
        </Stack>
        <Stack>
          <Typography fontWeight={fontWeight['500']} textTransform={'uppercase'}>
            {fixture.date ? dateToString(fixture.date) : '-'}
          </Typography>
          <Typography color={Colors.storm}>{fixture.stage?.name}</Typography>
        </Stack>
        <KebabMenu options={options} />
      </FixturesListItem>
      {isRecordingDialogOpen ? (
        <EditRecordingIdsDialog
          isOpen={isRecordingDialogOpen}
          setIsOpen={setIsRecordingDialogOpen}
          fixtureId={fixture.id}
        />
      ) : null}
      {isTacticalAnalysesDialogOpen && (
        <TacticalAnalysesDialog
          open={isTacticalAnalysesDialogOpen}
          onClose={() => setIsTacticalAnalysesDialogOpen(false)}
          recordingIds={fixture.recordings.map(({ id }) => id)}
        />
      )}
      <ConfirmDialog
        type={'warning'}
        title={`You sure you want delete "${fixture.name}" fixture?`}
        cancelLabel={'Cancel'}
        confirmLabel={'Delete'}
        onConfirm={handleDeleteFixture}
        isOpen={isConfirmDeleteDialogOpen}
        setIsOpen={setIsConfirmDeleteDialogOpen}
      />
    </>
  );
};
