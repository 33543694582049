import { Button, Stack } from '@mui/material';
import { IconClose } from 'kognia-ui/icons/IconClose';
import { IconDone } from 'kognia-ui/icons/IconDone';
import { IconPlay } from 'kognia-ui/icons/IconPlay';
import React, { useCallback, useEffect, useState } from 'react';

import { invalidatePlaylistQuery } from 'api/playlist/usePlaylist';
import { useUpdatePlaylistItem } from 'api/playlist/useUpdatePlaylistItem';
import { useMapVideos } from 'entities/playlist/hooks/use-map-videos/useMapVideos';
import Spinner from 'shared/components/spinner';
import {
  useVideoPlayerActions,
  useVideoPlayerPlayingMode,
  useVideoPlayerPlaylistItem,
  useVideoPlayerState,
} from 'shared/components/video-player';
import { useCurrentVideoSource } from 'shared/components/video-player/hooks';
import { PLAYER_STATES } from 'shared/components/video-player/state/states';
import { PlaylistItemType } from 'shared/components/video-player/types';
import { getVideoSourceByIndex } from 'shared/components/video-player/util';
import VideoPlayerComponent from 'shared/components/video-player/video-player-component';
import { Playlist } from 'shared/types';
import { round } from 'shared/utils/round';
import { secondsAsDuration } from 'shared/utils/seconds-as-duration';
import styles from 'widgets/playlist/user-playlist/ui/playlist-item-trim-player/PlaylistItemTrim.module.scss';
import {
  TrimSlider,
  ValueLabelComponent,
} from 'widgets/playlist/user-playlist/ui/playlist-item-trim-player/ui/TrimSlider';
import { generateTrimmedPlaylistItem } from 'widgets/playlist/user-playlist/ui/playlist-item-trim-player/utils/generateTrimmedPlaylistItem';

interface Props {
  onCancel: () => void;
  onConfirm: (playlistItem: PlaylistItemType) => void;
  playlistId: string;
}

const generateState = (startTime: number, endTime: number) => {
  const correction = 10;
  const min = round(startTime) - correction;
  const max = round(endTime) + correction;

  const sliderMin = min > 0 ? min : 0;

  return {
    min: sliderMin,
    max,
  };
};

const INITIAL_CLIP_DURATION = -1;
const INITIAL_VALUE = [-1, -1];
const INITIAL_LAST_VALUE = [-1, -1];
const INITIAL_SLIDER_STATE = { min: 0, max: 1 };

const PlaylistItemTrimPlayer = ({ onCancel, onConfirm, playlistId }: Props) => {
  const mapVideos = useMapVideos();
  const actions = useVideoPlayerActions();
  const playingMode = useVideoPlayerPlayingMode();
  const videoSource = useCurrentVideoSource();
  const playlistItem = useVideoPlayerPlaylistItem(playlistId);
  const [value, setValue] = useState(INITIAL_VALUE);
  const [sliderState, setSliderState] = useState(INITIAL_SLIDER_STATE);
  const [lastValue, setLastValue] = useState(INITIAL_LAST_VALUE);
  const [clipDuration, setClipDuration] = useState(INITIAL_CLIP_DURATION);
  const { state } = useVideoPlayerState();

  const isTrimmingReady = value[0] >= 0 && clipDuration >= 0;

  const handleReplacePlaylistItem = useCallback(
    (playlist: Playlist) => {
      const updatedPlaylistItem: PlaylistItemType | undefined = mapVideos(playlist).find(
        (item) => item.id === playlistItem.id,
      );
      if (updatedPlaylistItem) {
        onConfirm(updatedPlaylistItem);
      }
      onCancel();
    },
    [mapVideos, onConfirm, onCancel, playlistItem],
  );
  const { updatePlaylistItem, isPending } = useUpdatePlaylistItem(playlistId, handleReplacePlaylistItem);

  useEffect(() => {
    if (videoSource.endTime - videoSource.startTime === 0) return;

    if (sliderState === INITIAL_SLIDER_STATE) {
      setSliderState(generateState(videoSource.startTime, videoSource.endTime));
    }
    if (clipDuration === INITIAL_CLIP_DURATION) {
      setClipDuration(videoSource.endTime - videoSource.startTime);
    }
    if (value === INITIAL_VALUE) {
      setValue([videoSource.startTime, videoSource.endTime]);
    }
    if (lastValue === INITIAL_LAST_VALUE) {
      setLastValue([videoSource.startTime, videoSource.endTime]);
    }
  }, [videoSource, playingMode, playlistItem, clipDuration, value, lastValue, sliderState]);

  const handleConfirm = useCallback(() => {
    updatePlaylistItem(
      playlistItem.id,
      { name: playlistItem.name, timeRange: { start: value[0], end: value[1] } },
      () => {
        invalidatePlaylistQuery();
      },
    );
  }, [updatePlaylistItem, playlistItem, value]);

  const handleChange = useCallback(
    (event: Event, newValue: number | number[]) => {
      if (!Array.isArray(newValue)) return;

      const start = newValue[0] < value[1] ? newValue[0] : value[0];
      const end = newValue[1] > value[0] ? newValue[1] : value[1];

      setClipDuration(end - start);
      setSliderState(generateState(start, end));
      setValue([start, end]);
    },
    [value],
  );

  const handleChangeCommit = useCallback(() => {
    const videoSource = getVideoSourceByIndex(playlistItem, playingMode, 0);
    const hasStartTimeChanged = value[0] !== lastValue[0];
    const hasEndTimeChanged = value[1] !== lastValue[1];

    setLastValue(value);

    if (hasStartTimeChanged) {
      return actions.updatePlaylistItem(
        generateTrimmedPlaylistItem({
          playlistItem,
          duration: videoSource.endTime - value[0],
          startTime: value[0],
          endTime: videoSource.endTime,
        }),
        value[0],
      );
    }

    if (hasEndTimeChanged) {
      return actions.updatePlaylistItem(
        generateTrimmedPlaylistItem({
          playlistItem,
          duration: value[1] - videoSource.startTime,
          startTime: videoSource.startTime,
          endTime: value[1],
        }),
        value[1],
      );
    }
  }, [actions, lastValue, playingMode, playlistItem, value]);

  return (
    <Stack flexDirection='column' hidden={isTrimmingReady ? undefined : true} style={{ height: '100%' }}>
      <VideoPlayerComponent disabledTacticDrawings={playlistItem && !playlistItem.showOverlays} />
      {state !== PLAYER_STATES.IDLE && (
        <>
          <div className={styles.content}>
            <IconPlay size='small' color='primary' />
            <div>{playlistItem.name}</div>
            <Stack direction='row' justifyContent='flex-end' flex={1} alignItems='center' gap={1}>
              {isPending ? (
                <Spinner />
              ) : (
                <Button
                  variant='contained'
                  size='small'
                  color='primary'
                  onClick={handleConfirm}
                  sx={{ minWidth: 0, padding: 0 }}
                >
                  <IconDone sx={{ color: 'common.white' }} />
                </Button>
              )}
              {!isPending && (
                <Button variant='contained' color='secondary' onClick={onCancel} sx={{ minWidth: 0, padding: 0 }}>
                  <IconClose sx={{ color: 'common.white' }} />
                </Button>
              )}
            </Stack>
          </div>
          <div className={styles.slider}>
            <TrimSlider
              min={sliderState.min}
              max={sliderState.max}
              step={1}
              value={value}
              components={{
                ValueLabel: ValueLabelComponent,
              }}
              valueLabelFormat={(seconds: number) => {
                return secondsAsDuration(seconds, false);
              }}
              onChangeCommitted={handleChangeCommit}
              onChange={handleChange}
              valueLabelDisplay='on'
              aria-labelledby='video-trim'
            />
            <div className={styles.duration}>{secondsAsDuration(clipDuration, false)}</div>
          </div>
        </>
      )}
    </Stack>
  );
};

export default PlaylistItemTrimPlayer;
