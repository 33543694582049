import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosResponseHeaders,
  RawAxiosResponseHeaders,
} from 'axios';

import { HTTPMethod } from 'api/types';

export type BackendApiRequestTypes =
  | HTTPMethod.GET
  | HTTPMethod.POST
  | HTTPMethod.PATCH
  | HTTPMethod.PUT
  | HTTPMethod.DELETE;

axios.defaults.withCredentials = true;

export type UseBackendApiParams = AxiosRequestConfig['params'];

const handleError = (error: AxiosError) => {
  throw error;
};

export const callBackendApi = <T>(
  url: string,
  requestType: BackendApiRequestTypes,
  onSuccess: (response: T, headers?: RawAxiosResponseHeaders | AxiosResponseHeaders) => void = () => {},
  params: UseBackendApiParams = {},
): Promise<any> => {
  switch (requestType) {
    case HTTPMethod.GET:
      return axios
        .get(url)
        .then((response: AxiosResponse) => onSuccess(response.data, response.headers))
        .catch((e) => handleError(e));
    case HTTPMethod.PATCH:
      return axios
        .patch(url, params)
        .then((response) => onSuccess(response.data))
        .catch((e) => handleError(e));
    case HTTPMethod.POST:
      return axios
        .post(url, params)
        .then((response) => onSuccess(response.data))
        .catch((e) => handleError(e));
    case HTTPMethod.PUT:
      return axios
        .put(url, params)
        .then((response) => onSuccess(response.data))
        .catch((e) => handleError(e));
    case HTTPMethod.DELETE:
      return axios
        .delete(url, params)
        .then((response) => onSuccess(response.data))
        .catch((e) => handleError(e));
  }
};
