import { Stack } from '@mui/material';
import { IconClose } from 'kognia-ui/icons/IconClose';
import { IconCopyTo } from 'kognia-ui/icons/IconCopyTo';
import { IconDownload } from 'kognia-ui/icons/IconDownload';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PLAYLIST_TIMELINE_HEADER_HEIGHT } from 'entities/playlist/config/Playlist.config';
import {
  useBulkSelectedItems,
  useResetBulkSelectedItems,
  useSetBulkSelectedItems,
} from 'entities/playlist/hooks/useBulkSelectedItems';
import { useIsBulkModeActive, useSetIsBulkModeActive } from 'entities/playlist/hooks/useIsBulkModeActive';
import { ItemsListContainer } from 'entities/playlist/ui/ItemsListContainer';
import { PlaylistItemPositionWrapper } from 'entities/playlist/ui/PlaylistItemPositionWrapper';
import { PlaylistItemsEmpty } from 'entities/playlist/ui/PlaylistItemsEmpty';
import { DownloadPlaylistItemsModal } from 'features/playlist/download-playlist-items-modal/DownloadPlaylistItemsModal';
import { PlaylistItemsList, RowProps } from 'features/playlist/PlaylistItemsList';
import { SelectedItemsCopyToDialog } from 'features/playlist/SelectedItemsCopyToDialog';
import { TemporaryPlaylistItem } from 'features/playlist/temporary-playlist-items-list/ui/temporary-playlist-item/TemporaryPlaylistItem';
import { BulkButton } from 'shared/components/bulk-button/BulkButton';
import { CheckboxWithCustomColor } from 'shared/components/CheckboxWithCustomColor/CheckboxWithCustomColor';
import { ItemsListBulk } from 'shared/components/items-list-bulk/ItemsListBulk';
import { usePlaylistItems } from 'shared/components/video-player';
import { PlaylistItemType } from 'shared/components/video-player/types';
import { Playlist } from 'shared/types/index';

interface Props {
  onPlaylistOrderChange?: (currentVideoIndex: number, newVideoIndex: number) => void;
  playlist: Playlist;
}

export const TemporaryPlaylistItemsList = ({ playlist }: Props) => {
  const { t } = useTranslation();
  const enabledBulkMode = useIsBulkModeActive(playlist.id);
  const setEnabledBulkMode = useSetIsBulkModeActive(playlist.id);

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const selectedItems = useBulkSelectedItems(playlist.id);
  const setSelectedItems = useSetBulkSelectedItems(playlist.id);
  const resetSelectedItems = useResetBulkSelectedItems(playlist.id);
  const [copyToModalOpen, setCopyToModalOpen] = useState(false);

  const playlistItems = usePlaylistItems();
  const downloadRef = useRef<HTMLDivElement | null>(null);
  const copyToRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (selectedItems.length > 0 && !enabledBulkMode) {
      resetSelectedItems();
    }
  }, [enabledBulkMode, resetSelectedItems, selectedItems.length]);

  const playlistItemsIds = playlistItems.map((item) => item.id);

  const allItemsSelected = selectedItems.length === playlistItems.length;
  const selectAllIndeterminate = selectedItems.length > 0 && !allItemsSelected;

  const handleSelectAll = useCallback(() => {
    if (selectAllIndeterminate || allItemsSelected) {
      return resetSelectedItems();
    }

    return setSelectedItems(playlistItemsIds);
  }, [allItemsSelected, playlistItemsIds, resetSelectedItems, selectAllIndeterminate, setSelectedItems]);

  const PlaylistItemElement = useCallback(
    (currentPlaylistItem: PlaylistItemType, index: number) => (
      <PlaylistItemPositionWrapper index={index}>
        <TemporaryPlaylistItem
          isChecked={selectedItems.includes(currentPlaylistItem.id)}
          key={`playlist-video-${currentPlaylistItem.id}`}
          playlist={playlist}
          playlistItem={currentPlaylistItem}
          videoIndex={index}
        />
      </PlaylistItemPositionWrapper>
    ),
    [playlist, selectedItems],
  );

  const Row = useCallback(
    ({ data, index }: RowProps) => {
      const currentPlaylistItem = data[index];
      return <>{PlaylistItemElement(currentPlaylistItem, index)}</>;
    },
    [PlaylistItemElement],
  );

  const handleSetDisabledBulkMode = useCallback(() => {
    setEnabledBulkMode(false);
  }, [setEnabledBulkMode]);

  const handleSetDownloadModalOpen = useCallback(() => {
    setIsDownloadModalOpen(true);
  }, [setIsDownloadModalOpen]);

  const handleCloseCopyToModal = useCallback(() => {
    setCopyToModalOpen(true);
  }, [setCopyToModalOpen]);

  const handleOpenCopyToModal = useCallback(() => {
    setCopyToModalOpen(false);
    setEnabledBulkMode(false);
    resetSelectedItems();
  }, [setEnabledBulkMode, resetSelectedItems]);

  const handleCloseDownloadModal = useCallback(() => {
    setIsDownloadModalOpen(false);
  }, [setIsDownloadModalOpen]);

  return (
    <>
      {enabledBulkMode ? (
        <div>
          <ItemsListBulk height={PLAYLIST_TIMELINE_HEADER_HEIGHT}>
            <Stack direction='row' alignItems='center'>
              <BulkButton onClick={handleSetDisabledBulkMode} IconComponent={IconClose} />
              <Stack direction='row' alignItems='center'>
                <CheckboxWithCustomColor
                  customColor='white'
                  onClick={handleSelectAll}
                  checked={allItemsSelected}
                  indeterminate={selectAllIndeterminate}
                />
                {t('playlist-detail:all-clips')} ({selectedItems.length}/{playlistItems.length})
              </Stack>
            </Stack>

            <Stack direction='row' spacing={1}>
              <div ref={downloadRef}>
                <BulkButton
                  onClick={handleSetDownloadModalOpen}
                  IconComponent={IconDownload}
                  label={t('common:actions.download')}
                  disabled={isEmpty(selectedItems)}
                />
              </div>

              <div ref={copyToRef}>
                <BulkButton
                  onClick={handleCloseCopyToModal}
                  IconComponent={IconCopyTo}
                  label={t('playlist-detail:send-to-playlist')}
                  disabled={isEmpty(selectedItems)}
                />
              </div>

              {isDownloadModalOpen ? (
                <DownloadPlaylistItemsModal
                  downloadType={'temporaryPlaylist'}
                  onClose={handleCloseDownloadModal}
                  playlistData={{
                    ...playlist,
                    playlistItems: playlist.playlistItems.filter((item) => selectedItems.includes(item.id)),
                  }}
                />
              ) : null}
            </Stack>
          </ItemsListBulk>

          {copyToModalOpen && (
            <SelectedItemsCopyToDialog
              selectedItems={selectedItems}
              playlist={playlist}
              onClose={handleOpenCopyToModal}
              anchor={copyToRef.current}
            />
          )}
        </div>
      ) : null}
      <ItemsListContainer>
        {isEmpty(playlistItems) ? (
          <PlaylistItemsEmpty />
        ) : (
          <PlaylistItemsList Row={Row} playlistItems={playlistItems} />
        )}
      </ItemsListContainer>
    </>
  );
};
