import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';
import { OrganizationApi, OrganizationsApiResponse } from 'pages/backoffice/api/organizations/types';
import { Organization } from 'pages/backoffice/types/organizations';
import { chooseNextCursorValue } from 'shared/utils/choose-next-cursor-value';

export const transformOrganizations = (items: OrganizationApi[]): Organization[] => {
  return items.map((item) => ({
    id: item.uuid,
    name: item.name,
    competitions: item.competitions.map((competition) => ({
      id: competition.uuid,
      name: competition.name,
      logoUrl: competition.logo_url,
      country: competition.country,
      format: competition.format,
      gender: competition.gender,
      tier: competition.tier,
      region: competition.region,
      teamType: competition.team_type,
    })),
    logoUrl: item.logo_url,
  }));
};

export const transformPaginatedOrganizations = (
  response: OrganizationsApiResponse,
): InfinityQueryDataResult<Organization> => {
  return {
    data: {
      items: transformOrganizations(response.data),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
