import { IconCopy } from 'kognia-ui/icons/IconCopy';
import { IconCopyTo } from 'kognia-ui/icons/IconCopyTo';
import { IconDelete } from 'kognia-ui/icons/IconDelete';
import { IconDownload } from 'kognia-ui/icons/IconDownload';
import { IconEdit } from 'kognia-ui/icons/IconEdit';
import { IconOverlays } from 'kognia-ui/icons/IconOverlays';
import { IconTimeline } from 'kognia-ui/icons/IconTimeline';
import { IconTrim } from 'kognia-ui/icons/IconTrim';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { useDuplicatePlaylistItems } from 'api/playlist/useDuplicatePlaylistItems';
import { useMapVideos } from 'entities/playlist/hooks/use-map-videos/useMapVideos';
import { useHandleSetPlaylistItem } from 'entities/playlist/hooks/useHandleSetPlaylistItem';
import { getPlaylistItemFirstVideoSource } from 'entities/playlist/util/getPlaylistItemFirstVideoSource';
import { routes } from 'kognia/router/routes';
import { MenuListOption } from 'shared/components/kebab-menu';
import { useVideoPlayerActions, useVideoPlayerId } from 'shared/components/video-player/hooks';
import { PlaylistItemType } from 'shared/components/video-player/types';
import { useSetIsOverlayPanelOpen } from 'shared/components/video-player/video-player-component/hooks/overlay-panel';
import { Playlist } from 'shared/types/index';

export const useUserPlaylistItemOptions = (
  playlist: Playlist,
  playlistItem: PlaylistItemType,
  setTrimmingPlaylistItem: (id: string) => void,
  setCopyToAnchor: (value: HTMLElement | null) => void,
  setIsOpenRenameDialog: (value: boolean) => void,
  setIsOpenDeleteDialog: (value: boolean) => void,
  setIsDownloadModalOpen: (value: boolean) => void,
  onPlaylistItemDuplicate?: () => void,
): ((hasOverlays: boolean) => MenuListOption[]) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mapVideos = useMapVideos();
  const playerId = useVideoPlayerId();
  const actions = useVideoPlayerActions();
  const handleSetPlaylistItem = useHandleSetPlaylistItem(playlistItem);
  const setIsOverlayPanelOpenState = useSetIsOverlayPanelOpen(playerId);
  const { duplicatePlaylistItems } = useDuplicatePlaylistItems(playlist.id);

  const handleDuplicate = useCallback(
    () =>
      duplicatePlaylistItems({
        playlistItemIds: [playlistItem.id],
        options: {
          onSuccess: (data: Playlist) => {
            onPlaylistItemDuplicate && onPlaylistItemDuplicate();
            actions.replacePlaylistItems(mapVideos(data));
            actions.resumeStandBy();
          },
        },
      }),
    [duplicatePlaylistItems, playlistItem.id, onPlaylistItemDuplicate, actions, mapVideos],
  );

  const handleOpenCopyToDialog = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setCopyToAnchor(event.currentTarget);
    },
    [setCopyToAnchor],
  );

  const playlistItemVideoSource = getPlaylistItemFirstVideoSource(playlistItem);

  const handleGoToTimeline = useCallback(
    (event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      event && event.stopPropagation();
      const path = generatePath(routes.PERFORMANCE_REPORT_TIMELINE, { id: playlistItem.recordingId });

      navigate(path, { state: { initialStartTimeFromClip: playlistItemVideoSource?.startTime, playlist } });
    },
    [navigate, playlist, playlistItem.recordingId, playlistItemVideoSource?.startTime],
  );

  return useCallback(
    (hasOverlays: boolean) => {
      const overlayOption = {
        displayText: t('video-player:overlays.edit-overlays'),
        icon: <IconOverlays size={'small'} />,
        onClick: () => {
          handleSetPlaylistItem(false);
          setIsOverlayPanelOpenState(true);
        },
      };
      const options = [
        {
          displayText: t('common:actions.rename'),
          icon: <IconEdit size={'small'} />,
          onClick: () => {
            setIsOpenRenameDialog(true);
            actions.handleStandBy();
          },
        },
        {
          displayText: t('common:actions.trim'),
          icon: <IconTrim size={'small'} />,
          onClick: () => {
            setTrimmingPlaylistItem(playlistItem.id);
          },
        },
        {
          displayText: t('common:actions.download'),
          icon: <IconDownload size={'small'} />,
          onClick: () => {
            setIsDownloadModalOpen(true);
            actions.handleStandBy();
          },
        },
        {
          displayText: t('common:actions.duplicate'),
          icon: <IconCopy size={'small'} />,
          onClick: () => handleDuplicate(),
        },
        {
          displayText: t('common:actions.copy-to'),
          icon: <IconCopyTo size={'small'} />,
          onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            handleOpenCopyToDialog(event);
            actions.handleStandBy();
          },
          avoidCloseOnClick: true,
        },
        {
          displayText: t('common:actions.remove'),
          icon: <IconDelete size={'small'} />,
          onClick: () => {
            setIsOpenDeleteDialog(true);
            actions.handleStandBy();
          },
        },
        {
          displayText: '',
        },
        {
          displayText: t('playlist-detail:go-to-timeline'),
          icon: <IconTimeline size={'small'} />,
          onClick: handleGoToTimeline,
        },
      ];
      return hasOverlays && playlistItem.showOverlays ? [overlayOption, ...options] : options;
    },
    [
      actions,
      handleDuplicate,
      handleGoToTimeline,
      handleOpenCopyToDialog,
      handleSetPlaylistItem,
      playlistItem.id,
      playlistItem.showOverlays,
      setIsDownloadModalOpen,
      setIsOpenDeleteDialog,
      setIsOpenRenameDialog,
      setIsOverlayPanelOpenState,
      setTrimmingPlaylistItem,
      t,
    ],
  );
};
