import { useEffect } from 'react';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { transformPlaylist } from 'api/playlist/transformers';
import { PlaylistApiResponse } from 'api/playlist/types';
import { playlistPreview } from 'api/routes';
import { BackendApiError, backendApiErrorParser } from 'api/utils/backend-api-error-parser';
import { Playlist } from 'shared/types';
import { DashboardPlaylistGenericPayload } from 'shared/types/embeddedDashboard';

export const useTemporaryPlaylist = ({ playlist }: { playlist: DashboardPlaylistGenericPayload | undefined }) => {
  const { mutate, isSuccess, isError, isPending, data } = useMutationRequest<
    PlaylistApiResponse,
    Playlist,
    BackendApiError
  >({
    transformer: transformPlaylist,
    errorMessage: (response) => backendApiErrorParser(response, 'Error generating playlist'),
  });

  useEffect(() => {
    if (!playlist) return;

    return mutate({
      url: playlistPreview,
      data: {
        name: playlist.name,
        items: playlist.items.map((item) => ({
          startFrame: item.start_frame,
          endFrame: item.end_frame,
          recordingId: item.recording_id,
          name: item.name,
        })),
      },
    });
  }, [mutate, playlist]);

  return {
    isSuccess,
    isError,
    isPending,
    data: playlist ? data : null,
  };
};
