import { createContext, useEffect, useMemo, useState } from 'react';

import {
  useCurrentPlaylistItem,
  useCurrentTime,
  useDuration,
  useIsBuffering,
  useIsCurrentPlaylistItem,
  useIsSeeking,
  usePlayerCurrentSource,
  usePlaylistCurrentPlaylistItemId,
  usePlaylistItems,
  useVideoPlayerActions,
  useVideoPlayerPlayingMode,
  useVideoPlayerPlaylistItem,
  useVideoPlayerRef,
  useVideoPlayerState,
} from 'shared/components/video-player/hooks';
import { usePlayerStateMachine } from 'shared/components/video-player/state';
import { PlayerContainerType, PlayingMode } from 'shared/components/video-player/types';
import { PlayerType, PlaylistItemType } from 'shared/components/video-player/types';

export interface VideoPlayerActions {
  skipBackward5s: () => void;
  changePlayingMode: (playingMode: PlayingMode, tryToKeepCurrentTime?: boolean, autoplay?: boolean) => void;
  skipForward5s: () => void;
  handleStandBy: () => void;
  jumpToTimeInMatch: (time: number) => void;
  jumpToTimePercent: (percent: number) => void;
  nextPlaylistItem: () => void;
  autoplayNextPlaylistItem: () => void;
  nextVideoSource: () => void;
  previousVideoSource: () => void;
  restart: () => void;
  refresh: () => void;
  pause: () => void;
  play: () => void;
  replacePlaylistItems: (playlistItems: PlaylistItemType[]) => void;
  setPlaylist: (
    playlist: PlaylistItemType[],
    playingMode: PlayingMode,
    autoplay?: boolean,
    tryToKeepCurrentTime?: boolean,
  ) => void;
  previousPlaylistItem: () => void;
  removePlaylistItem: (id: string) => void;
  removePlaylistItems: (ids: string[]) => void;
  reorder: (currentVideoIndex: number, newVideoIndex: number) => void;
  replay: () => void;
  resumeStandBy: () => void;
  setPlaylistItem: (id: string, autoPlay: boolean) => void;
  updatePlaylistItem: (playlistItem: PlaylistItemType, currenTime?: number) => void;
  updatePlaylistItems: (playlistItems: PlaylistItemType[]) => void;
  loadPlaylist: (playlistItems: PlaylistItemType[], initialStartTime: number) => void;
}

interface VideoPlayerFixedState {
  videoPlayerRef: PlayerType;
  videoPlayerContainerRef: PlayerContainerType;
  videoPlayerId: string;
  refreshData?: (() => void) | (() => Promise<void>);
  actions: VideoPlayerActions;
}

type VideoPlayerStateProviderProps = {
  children: React.ReactNode;
  refreshData?: (() => void) | (() => Promise<void>);
  playerId: string;
  playingMode: PlayingMode;
  playlistItems: PlaylistItemType[];
  initialStartTime?: number | undefined;
};

export const VideoPlayerStateFixedContext = createContext<{ state: VideoPlayerFixedState } | undefined>(undefined);

function VideoPlayerStateProvider({
  children,
  refreshData,
  playerId,
  playingMode,
  playlistItems,
  initialStartTime,
}: VideoPlayerStateProviderProps) {
  const [firstLoad, setFirstLoad] = useState(true);
  const { actions, player, playerContainerRef } = usePlayerStateMachine(playerId, playingMode);

  useEffect(() => {
    if (firstLoad) {
      actions.loadPlaylist(playlistItems, initialStartTime);
      setFirstLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoad, playlistItems, initialStartTime]);

  return (
    <VideoPlayerStateFixedContext.Provider
      value={useMemo(
        () => ({
          state: {
            refreshData,
            videoPlayerId: playerId,
            videoPlayerRef: player,
            videoPlayerContainerRef: playerContainerRef,
            actions,
          },
        }),
        [actions, player, playerContainerRef, playerId, refreshData],
      )}
    >
      {children}
    </VideoPlayerStateFixedContext.Provider>
  );
}

export {
  VideoPlayerStateProvider,
  useCurrentTime,
  useDuration,
  useIsBuffering,
  useIsCurrentPlaylistItem,
  useIsSeeking,
  usePlayerCurrentSource,
  useVideoPlayerRef,
  useCurrentPlaylistItem,
  useVideoPlayerActions,
  usePlaylistCurrentPlaylistItemId,
  usePlaylistItems,
  useVideoPlayerPlayingMode,
  useVideoPlayerPlaylistItem,
  useVideoPlayerState,
};
