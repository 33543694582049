import { useMemo } from 'react';

import { useCurrentClient } from 'shared/hooks/use-current-client/useCurrentClient';
import { ClientBranding } from 'shared/types/user/types';
import { getBrandingFromClient } from 'shared/utils/getBrandingFromClient';

export const useBranding = (): ClientBranding => {
  const currentClient = useCurrentClient();

  return useMemo(() => getBrandingFromClient(currentClient), [currentClient]);
};
