import { useMachine } from '@xstate/react';
import { useEffect, useRef } from 'react';
import { createMachine, send } from 'xstate';

import {
  usePlayerSetIsPlaying,
  usePlayerSetPlaylist,
  usePlayerState,
  usePlayerUpdateStandBy,
  useSetCurrentTime,
} from 'shared/components/video-player/state/atoms/hooks';
import { changeSourceMachine } from 'shared/components/video-player/state/change-source-and-time-machine';
import { useGenerateVideoPlayerActions } from 'shared/components/video-player/state/hooks';
import {
  PLAYER_ACTIONS,
  PLAYER_STATES,
  READY_STATES,
  VIDEO_PLAYER_MACHINE_ID,
} from 'shared/components/video-player/state/states';
import {
  addPlaylistItems,
  changePlayingMode,
  firstPlaylistItem,
  fixPlaylistItemWithoutNoDuration,
  jumpToMatchTime,
  nextPlaylistItem,
  nextVideoSource,
  pause,
  play,
  previousPlaylistItem,
  previousVideoSource,
  removePlaylistItem,
  removePlaylistItems,
  reorderPlaylistItem,
  restart,
  seekNewTime,
  setPlayerToPause,
  setPlayerToPlay,
  setPlaylistItem,
  setPlaylistItems,
  setResumeStandBy,
  setStandBy,
  setVideoRef,
  skipBackward,
  skipForward,
  toggleFullScreen,
  updatePlaylistItem,
  updatePlaylistItems,
} from 'shared/components/video-player/state/utils/actions';
import {
  hasPlaylistItemNoDuration,
  isCurrentPlaylistItem,
  isInStandBy,
  isLastPlaylistItem,
  isLastVideoSource,
  isNotLastPlaylistItem,
  isPaused,
  isPlayerNotReady,
  isPlayerReady,
  isPlaying,
  isPlaylistEmpty,
  shouldAutoplayNextPlaylistItem,
} from 'shared/components/video-player/state/utils/guards';
import {
  PlayerStateMachineContext,
  PlayerStateMachineEvent,
  PlayerType,
  PlayingMode,
  SetPlaylistAction,
  SetReplacePlaylistItemAction,
} from 'shared/components/video-player/types';

const defaultPlayerState = (playingMode: PlayingMode, playerId: string, player: PlayerType, currentTime: number) => {
  const initialState: PlayerStateMachineContext = {
    playerId,
    playlist: {
      preferredPlayingMode: playingMode,
      currentSelectedPlayingMode: playingMode,
      currentPlaylistItemId: '',
      playlistItems: [],
      playingItem: {
        currentSourceTime: 0,
        playlistItem: {
          videoTypes: [],
          duration: 0,
          name: '',
          id: '',
          index: 0,
          recordingId: '',
          hasHomographies: false,
          fundamentalsSelected: {
            tacticalAnalysisId: undefined,
            fundamentalsSelected: [],
          },
          showOverlays: false,
        },
        videoSourceIndex: 0,
      },
    },
    isFullScreen: false,
    isInStandBy: false,
    isPlaying: false,
    autoPlayNextPlaylistItem: true,
    currentTime,
    videoRef: player,
  };
  return {
    context: initialState,
  };
};

export const playerStateMachine = createMachine<PlayerStateMachineContext, PlayerStateMachineEvent>(
  {
    predictableActionArguments: true,
    id: VIDEO_PLAYER_MACHINE_ID,
    initial: PLAYER_STATES.IDLE,
    states: {
      [PLAYER_STATES.IDLE]: {
        on: {
          [PLAYER_ACTIONS.LOAD_PLAYLIST]: [
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
              actions: ['setPlaylistItems'],
              cond: 'isPlayerReady',
            },
            {
              target: PLAYER_STATES.IDLE,
              actions: ['setPlaylistItems'],
              cond: 'isPlayerNotReady',
            },
          ],
          [PLAYER_ACTIONS.REFRESH]: [
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
            },
          ],
        },
      },
      [PLAYER_STATES.EMPTY_PLAYLIST]: {
        on: {
          [PLAYER_ACTIONS.REPLACE_PLAYLIST_ITEMS]: {
            target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
            actions: ['addPlaylistItems'],
          },
          [PLAYER_ACTIONS.REPLACE_PLAYLIST]: [
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
              actions: ['setPlaylistItems'],
            },
          ],
          [PLAYER_ACTIONS.LOAD_PLAYLIST]: [
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
              actions: ['setPlaylistItems'],
            },
          ],
        },
      },
      [PLAYER_STATES.ERROR]: {
        on: {
          [PLAYER_ACTIONS.LOAD_PLAYLIST]: PLAYER_STATES.LOADING_VIDEO_SOURCE,
        },
      },
      [PLAYER_STATES.LOADING_VIDEO_SOURCE]: {
        on: {
          [PLAYER_ACTIONS.STAND_BY]: {
            target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
            actions: ['setStandBy'],
          },
          [PLAYER_ACTIONS.PLAY]: {
            target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
            actions: ['setPlayerToPlay', 'play'],
          },
          [PLAYER_ACTIONS.PAUSE]: {
            target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
            actions: ['setPlayerToPause', 'pause'],
          },
          [PLAYER_ACTIONS.RESUME_STAND_BY]: {
            target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
            actions: ['setResumeStandBy'],
          },
          [PLAYER_ACTIONS.CHANGE_PLAYING_MODE]: {
            target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
            actions: ['changePlayingMode', 'pause'],
          },
          [PLAYER_ACTIONS.REPLACE_PLAYLIST]: [
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
              actions: ['setPlaylistItems'],
            },
          ],
          [PLAYER_ACTIONS.LOAD_PLAYLIST]: [
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
              actions: ['setPlaylistItems'],
            },
          ],
        },
        always: {
          target: PLAYER_STATES.EMPTY_PLAYLIST,
          cond: 'isPlaylistEmpty',
        },
        invoke: {
          src: changeSourceMachine,
          onDone: { target: PLAYER_STATES.READY },
          data: {
            playingMode: (context: PlayerStateMachineContext) => context.playlist.currentSelectedPlayingMode,
            videoRef: (context: PlayerStateMachineContext) => context.videoRef,
            playlistItem: (context: PlayerStateMachineContext) => context.playlist.playingItem.playlistItem,
            videoSourceIndex: (context: PlayerStateMachineContext) => context.playlist.playingItem.videoSourceIndex,
            currentTime: (context: PlayerStateMachineContext): number => context.currentTime,
          },
        },
      },
      [PLAYER_STATES.READY]: {
        initial: READY_STATES.IDLE,
        on: {
          [PLAYER_ACTIONS.UPDATE_PLAYLIST_ITEMS]: [
            {
              target: PLAYER_STATES.READY,
              actions: ['updatePlaylistItems'],
            },
          ],
          [PLAYER_ACTIONS.REORDER_PLAYLIST_ITEM]: [
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
              actions: ['reorderPlaylistItem', 'pause'],
            },
          ],
          [PLAYER_ACTIONS.REPLACE_PLAYLIST_ITEMS]: {
            target: PLAYER_STATES.READY,
            actions: ['addPlaylistItems'],
          },
          [PLAYER_ACTIONS.REMOVE_PLAYLIST_ITEM]: [
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
              actions: ['removePlaylistItem', 'pause'],
            },
          ],
          [PLAYER_ACTIONS.REMOVE_PLAYLIST_ITEMS]: [
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
              actions: ['removePlaylistItems', 'pause'],
            },
          ],
          [PLAYER_ACTIONS.NEXT_PLAYLIST_ITEM]: [
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
              actions: ['nextPlaylistItem', 'pause'],
            },
          ],
          [PLAYER_ACTIONS.AUTOPLAY_NEXT_PLAYLIST_ITEM]: [
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
              cond: 'shouldAutoplayNextPlaylistItem',
              actions: ['nextPlaylistItem', 'pause'],
            },
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
              cond: 'isLastPlaylistItem',
              actions: ['setPlayerToPause', 'pause', 'firstPlaylistItem'],
            },
            {
              target: PLAYER_STATES.READY,
              actions: ['setPlayerToPause', 'pause'],
            },
          ],
          [PLAYER_ACTIONS.PREVIOUS_PLAYLIST_ITEM]: [
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
              actions: ['previousPlaylistItem', 'pause'],
            },
          ],
          [PLAYER_ACTIONS.NEXT_VIDEO_SOURCE]: [
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
              cond: 'isNotLastPlaylistItem',
              actions: ['nextVideoSource', 'pause'],
            },
            {
              target: PLAYER_STATES.READY,
              cond: 'isLastVideoSource',
              actions: ['setPlayerToPause', 'pause'],
            },
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
              actions: ['nextVideoSource', 'pause'],
            },
          ],
          [PLAYER_ACTIONS.PREVIOUS_VIDEO_SOURCE]: [
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
              actions: ['previousVideoSource', 'pause'],
            },
          ],
          [PLAYER_ACTIONS.UPDATE_PLAYLIST_ITEM]: [
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
              actions: ['updatePlaylistItem'],
            },
          ],
          [PLAYER_ACTIONS.CHANGE_PLAYING_MODE]: [
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
              actions: ['changePlayingMode', 'pause'],
            },
          ],
          [PLAYER_ACTIONS.TOGGLE_FULL_SCREEN]: [
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
              actions: ['toggleFullScreen'],
            },
          ],
          [PLAYER_ACTIONS.JUMP_TO_MATCH_TIME]: [
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
              actions: ['pause', 'jumpToMatchTime', 'setPlayerToPlay'],
            },
          ],
          [PLAYER_ACTIONS.LOAD_PLAYLIST]: [
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
              actions: ['setPlaylistItems'],
            },
          ],
          [PLAYER_ACTIONS.REPLACE_PLAYLIST]: [
            {
              target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
              actions: [
                send((context, _event) => {
                  const event = _event as SetReplacePlaylistItemAction;

                  return {
                    type: PLAYER_ACTIONS.LOAD_PLAYLIST,
                    playlistItems: event.playlistItems,
                    playingMode: event.playingMode,
                    autoplay: event.autoplay,
                    tryToKeepCurrentTime: event.tryToKeepCurrentTime,
                  } as SetPlaylistAction;
                }),
              ],
            },
          ],
          [PLAYER_ACTIONS.RESUME_STAND_BY]: {
            target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
            actions: ['setResumeStandBy'],
          },
          [PLAYER_ACTIONS.RESTART]: {
            target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
            actions: ['setPlayerToPlay', 'restart'],
          },
        },
        states: {
          [READY_STATES.IDLE]: {
            always: [
              {
                target: READY_STATES.SEEKING_NEW_TIME,
                cond: 'hasPlaylistItemNoDuration',
                actions: ['fixPlaylistItemWithoutNoDuration'],
              },
              { target: READY_STATES.STAND_BY, cond: 'isInStandBy', actions: ['pause'] },
              { target: READY_STATES.PAUSED, cond: 'isPaused', actions: ['pause', 'setPlayerToPause'] },
              { target: READY_STATES.PLAYING, cond: 'isPlaying', actions: ['play', 'setPlayerToPlay'] },
            ],
          },
          [READY_STATES.STAND_BY]: {
            on: {
              [PLAYER_ACTIONS.REPLACE_PLAYLIST_ITEMS]: {
                target: READY_STATES.STAND_BY,
                actions: ['addPlaylistItems'],
              },
              [PLAYER_ACTIONS.RESUME_STAND_BY]: [
                {
                  target: READY_STATES.PLAYING,
                  cond: 'isPlaying',
                  actions: ['setResumeStandBy', 'play'],
                },
                { target: READY_STATES.PAUSED, cond: 'isPaused', actions: ['setResumeStandBy', 'pause'] },
              ],
              [PLAYER_ACTIONS.JUMP_TO_TIME_PERCENT]: {
                target: READY_STATES.STAND_BY,
                actions: ['seekNewTime', 'pause'],
              },
            },
          },
          [READY_STATES.SEEKING_NEW_TIME]: {
            invoke: {
              src: changeSourceMachine,
              onDone: { target: READY_STATES.IDLE },
              data: {
                playingMode: (context: PlayerStateMachineContext) => context.playlist.currentSelectedPlayingMode,
                videoRef: (context: PlayerStateMachineContext) => context.videoRef,
                playlistItem: (context: PlayerStateMachineContext) => context.playlist.playingItem.playlistItem,
                videoSourceIndex: (context: PlayerStateMachineContext) => context.playlist.playingItem.videoSourceIndex,
                currentTime: (context: PlayerStateMachineContext) => context.currentTime,
              },
            },
          },
          [PLAYER_STATES.LOADING_PLAYLIST_ITEM]: {
            always: { target: READY_STATES.SEEKING_NEW_TIME, actions: ['pause'] },
          },
          [READY_STATES.PLAYING]: {
            on: {
              [PLAYER_ACTIONS.REPLACE_PLAYLIST_ITEMS]: {
                target: READY_STATES.SEEKING_NEW_TIME,
                actions: ['addPlaylistItems'],
              },
              [PLAYER_ACTIONS.LOAD_PLAYLIST_ITEM]: [
                {
                  target: PLAYER_STATES.LOADING_PLAYLIST_ITEM,
                  actions: ['setPlaylistItem', 'pause'],
                },
              ],
              [PLAYER_ACTIONS.TOGGLE_PLAYING]: [
                { target: READY_STATES.PAUSED, cond: 'isPlaying', actions: ['setPlayerToPause', 'pause'] },
                { target: READY_STATES.PLAYING, cond: 'isPaused', actions: ['setPlayerToPlay', 'play'] },
              ],
              [PLAYER_ACTIONS.SKIP_FORWARD]: [
                {
                  target: READY_STATES.SEEKING_NEW_TIME,
                  actions: ['skipForward'],
                },
              ],
              [PLAYER_ACTIONS.SKIP_BACKWARD]: [
                {
                  target: READY_STATES.SEEKING_NEW_TIME,
                  actions: ['skipBackward'],
                },
              ],
              [PLAYER_ACTIONS.JUMP_TO_TIME_PERCENT]: {
                target: READY_STATES.SEEKING_NEW_TIME,
                actions: ['seekNewTime'],
              },
              [PLAYER_ACTIONS.STAND_BY]: { target: READY_STATES.STAND_BY, actions: ['pause', 'setStandBy'] },
              [PLAYER_ACTIONS.PAUSE]: { target: READY_STATES.PAUSED, actions: ['setPlayerToPause', 'pause'] },
            },
          },
          [READY_STATES.PAUSED]: {
            on: {
              [PLAYER_ACTIONS.REPLACE_PLAYLIST_ITEMS]: {
                target: READY_STATES.SEEKING_NEW_TIME,
                actions: ['addPlaylistItems'],
              },
              [PLAYER_ACTIONS.PAUSE]: { target: READY_STATES.PAUSED, actions: ['setPlayerToPause', 'pause'] },
              [PLAYER_ACTIONS.LOAD_PLAYLIST_ITEM]: [
                {
                  target: PLAYER_STATES.LOADING_PLAYLIST_ITEM,
                  actions: ['setPlaylistItem', 'pause'],
                },
              ],
              [PLAYER_ACTIONS.TOGGLE_PLAYING]: [
                { target: READY_STATES.PAUSED, cond: 'isPlaying', actions: ['setPlayerToPause', 'pause'] },
                { target: READY_STATES.PLAYING, cond: 'isPaused', actions: ['setPlayerToPlay', 'play'] },
              ],
              [PLAYER_ACTIONS.SKIP_FORWARD]: [
                {
                  target: READY_STATES.SEEKING_NEW_TIME,
                  actions: ['skipForward', 'pause'],
                },
              ],
              [PLAYER_ACTIONS.SKIP_BACKWARD]: [
                {
                  target: READY_STATES.SEEKING_NEW_TIME,
                  actions: ['skipBackward', 'pause'],
                },
              ],
              [PLAYER_ACTIONS.JUMP_TO_TIME_PERCENT]: {
                target: READY_STATES.SEEKING_NEW_TIME,
                actions: ['seekNewTime'],
              },
              [PLAYER_ACTIONS.PLAY]: {
                target: READY_STATES.PLAYING,
                actions: ['setPlayerToPlay', 'play'],
              },
              [PLAYER_ACTIONS.STAND_BY]: {
                target: READY_STATES.STAND_BY,
                actions: ['pause', 'setStandBy'],
              },
            },
          },
        },
        onDone: [
          { target: PLAYER_STATES.CHECKING_NEXT_PLAYLIST_ITEM, cond: 'isLastVideoSource' },
          {
            target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
            actions: ['nextVideoSource', 'pause'],
          },
        ],
      },
      [PLAYER_STATES.CHECKING_NEXT_PLAYLIST_ITEM]: {
        always: [
          {
            target: PLAYER_STATES.READY,
            cond: 'isLastPlaylistItem',
            actions: ['pause', 'setPlayerToPause'],
          },
          {
            target: PLAYER_STATES.LOADING_VIDEO_SOURCE,
            cond: 'isNotLastPlaylistItem',
            actions: ['pause', 'nextPlaylistItem'],
          },
        ],
      },
      [PLAYER_STATES.JUMP_TO_TIME_PERCENT]: {
        invoke: {
          src: changeSourceMachine,
          onDone: { target: PLAYER_STATES.READY },
          data: {
            videoRef: (context: PlayerStateMachineContext) => context.videoRef,
            playlistItem: (context: PlayerStateMachineContext) => context.playlist.playingItem.playlistItem,
            videoSourceIndex: (context: PlayerStateMachineContext) => context.playlist.playingItem.videoSourceIndex,
            currentTime: (context: PlayerStateMachineContext) => context.currentTime,
          },
        },
      },
    },
  },
  {
    actions: {
      changePlayingMode,
      fixPlaylistItemWithoutNoDuration,
      jumpToMatchTime,
      nextPlaylistItem,
      firstPlaylistItem,
      nextVideoSource,
      pause,
      play,
      previousPlaylistItem,
      previousVideoSource,
      removePlaylistItem,
      removePlaylistItems,
      reorderPlaylistItem,
      restart,
      seekNewTime,
      setPlayerToPause,
      setPlayerToPlay,
      setPlaylistItem,
      setPlaylistItems,
      updatePlaylistItems,
      addPlaylistItems,
      setResumeStandBy,
      setStandBy,
      setVideoRef,
      skipBackward,
      skipForward,
      toggleFullScreen,
      updatePlaylistItem,
    },
    guards: {
      hasPlaylistItemNoDuration,
      isInStandBy,
      isLastPlaylistItem,
      shouldAutoplayNextPlaylistItem,
      isCurrentPlaylistItem,
      isLastVideoSource,
      isNotLastPlaylistItem,
      isPaused,
      isPlayerNotReady,
      isPlayerReady,
      isPlaying,
      isPlaylistEmpty,
    },
  },
);

export const usePlayerStateMachine = (playerId: string, playingMode: PlayingMode) => {
  const [playerStatus, setPlayerState] = usePlayerState(playerId);
  const setIsPlaying = usePlayerSetIsPlaying(playerId);
  const setPlaylist = usePlayerSetPlaylist(playerId);
  const setIsInStandBy = usePlayerUpdateStandBy(playerId);
  const player = useRef<HTMLVmPlayerElement>(null);
  const playerContainerRef = useRef<HTMLDivElement>(null);
  const setCurrentTime = useSetCurrentTime(playerId);

  const [current, send] = useMachine<PlayerStateMachineContext, PlayerStateMachineEvent>(
    playerStateMachine,
    defaultPlayerState(playingMode, playerId, player, 0),
  );

  const actions = useGenerateVideoPlayerActions(current.context, send);

  useEffect(() => {
    if (!current.context.videoRef?.current?.ready) return;

    setCurrentTime(current.context.currentTime);
  }, [
    current.context.videoRef,
    current.context.currentTime,
    current.context.playlist.playingItem.videoSourceIndex,
    current.context.playlist.playingItem.playlistItem,
    setCurrentTime,
  ]);

  useEffect(() => {
    if (playerStatus !== current.value) {
      const value = current.value as PLAYER_STATES;
      setPlayerState(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.value]);

  useEffect(() => {
    setIsPlaying(current.context.isPlaying);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.context.isPlaying]);

  useEffect(() => {
    if (!current.context.videoRef?.current?.ready && current.context.playlist.currentPlaylistItemId === '') return;
    setPlaylist(current.context.playlist);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.context.playlist, current.context.videoRef]);

  useEffect(() => {
    setIsInStandBy(current.context.isInStandBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.context.isInStandBy]);

  return {
    PLAYER_STATES,
    READY_STATES,
    PLAYER_ACTIONS,
    actions,
    send,
    player,
    playerContainerRef,
  };
};
