import { useCallback } from 'react';

import { TimelineOverlaysSelectorPanel } from 'pages/tactical-analysis/components/tactical-analysis/timeline-overlays-selector-panel';
import { useTacticalAnalysisEpisodes } from 'pages/tactical-analysis/hooks/use-tactical-analysis-episodes';
import { useTacticalAnalysisMatch } from 'pages/tactical-analysis/hooks/use-tactical-analysis-match';
import { useTacticalAnalysisMatchSegments } from 'pages/tactical-analysis/hooks/use-tactical-analysis-match-segments';
import { useTimelinePlayingMode } from 'pages/tactical-analysis/hooks/use-timeline-playing-mode';
import { useVideoPlayerPlayingMode } from 'shared/components/video-player';
import { getPlayingMode } from 'shared/components/video-player/defaultPlayingModes';
import VideoPlayerComponent from 'shared/components/video-player/video-player-component';

type Props = {
  recordingId: string;
};

export const TacticalAnalysisVideoPlayer = ({ recordingId }: Props) => {
  const episodes = useTacticalAnalysisEpisodes(recordingId);
  const matchSegments = useTacticalAnalysisMatchSegments(recordingId);
  const match = useTacticalAnalysisMatch(recordingId);
  const playingMode = useVideoPlayerPlayingMode();
  const { setPlayingMode } = useTimelinePlayingMode(recordingId);

  const handleToggle = useCallback(() => {
    return setPlayingMode(
      getPlayingMode({
        playingMode,
        showOverlays: !playingMode.showOverlays,
      }),
    );
  }, [setPlayingMode, playingMode]);

  const showTacticDrawings = episodes.length > 0;
  const showOverlays = match.showOverlays;

  return (
    <>
      <VideoPlayerComponent
        matchSegments={matchSegments}
        onPlayingModeChange={handleToggle}
        showTacticDrawings={showTacticDrawings}
        showTacticDrawingsOnly
        showVideoPlayerBar
        showVideoSourceControls
        disabledTacticDrawings={!showOverlays}
      />
      {showOverlays && match.hasHomographies ? (
        <TimelineOverlaysSelectorPanel key={match.id} recordingId={recordingId} />
      ) : null}
    </>
  );
};
