import { Stack, Tooltip, Typography } from '@mui/material';
import { IconDownload } from 'kognia-ui/icons/IconDownload';
import { IconEye } from 'kognia-ui/icons/IconEye';
import { IconInfo } from 'kognia-ui/icons/IconInfo';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DownloadPlaylistItemsModal } from 'features/playlist/download-playlist-items-modal/DownloadPlaylistItemsModal';
import { DownloadCurrentPlaylistItemModal } from 'features/playlist/DownloadCurrentPlaylistItemModal';
import { SaveTemporaryPlaylistFeature } from 'features/playlist/save-temporary-playlist/SaveTemporaryPlaylist.feature';
import { TemporaryPlaylistItemsList } from 'features/playlist/temporary-playlist-items-list/TemporaryPlaylistItemsList';
import { PlaylistTitle } from 'shared/components/PlaylistTitle';
import { VerticalIconButton } from 'shared/components/vertical-icon-button';
import { useVideoPlayerActions } from 'shared/components/video-player';
import { Playlist } from 'shared/types';
import { PlaylistLayoutWidget } from 'widgets/playlist/playlist-layout/PlaylistLayout.widget';

type Props = {
  playlist: Playlist;
};

export const TemporaryPlaylist = ({ playlist }: Props) => {
  const { t } = useTranslation();
  const actions = useVideoPlayerActions();

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isCurrentItemDownloadModalOpen, setIsCurrentItemDownloadModalOpen] = useState(false);

  const handleDownloadModalClose = useCallback(() => setIsDownloadModalOpen(false), []);
  const handleDownloadModalOpen = useCallback(() => setIsDownloadModalOpen(true), []);

  const handleCloseDownloadCurrentItemModal = useCallback(() => {
    actions.resumeStandBy();
    setIsCurrentItemDownloadModalOpen(false);
  }, [actions]);

  return (
    <>
      <PlaylistLayoutWidget
        playlist={playlist}
        actionsListComponent={
          <>
            <VerticalIconButton Icon={IconDownload} onClick={handleDownloadModalOpen}>
              {t('common:actions.download')}
            </VerticalIconButton>
            <SaveTemporaryPlaylistFeature playlist={playlist} />
          </>
        }
        itemsListComponent={<TemporaryPlaylistItemsList playlist={playlist} />}
        titleComponent={
          <>
            <IconEye size={'small'} />
            <PlaylistTitle title={playlist.name} />
            <Tooltip
              placement={'right'}
              title={
                <Stack gap={1}>
                  {t('playlist-detail:read-only-playlist.read-only-info-title')}
                  <Typography variant={'body2'}>
                    {t('playlist-detail:read-only-playlist.read-only-info-description')}
                  </Typography>
                </Stack>
              }
            >
              <Stack>
                <IconInfo size={'small'} />
              </Stack>
            </Tooltip>
          </>
        }
      />
      <>
        {isDownloadModalOpen ? (
          <DownloadPlaylistItemsModal
            onClose={handleDownloadModalClose}
            playlistData={playlist}
            downloadType={'temporaryPlaylist'}
          />
        ) : null}
        <DownloadCurrentPlaylistItemModal
          playlist={playlist}
          isOpen={isCurrentItemDownloadModalOpen}
          onClose={handleCloseDownloadCurrentItemModal}
        />
      </>
    </>
  );
};
