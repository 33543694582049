import { Stack, Typography } from '@mui/material';
import { TeamAvatar } from 'kognia-ui/components/avatar/TeamAvatar';

import { MenuItemContentWrapper } from 'shared/components/MenuItemContentWrapper';

interface Props {
  name: string;
  logoUrl: string | null;
}

export const TeamRow = ({ logoUrl, name }: Props) => {
  return (
    <MenuItemContentWrapper>
      <Stack direction={'row'} gap={1} alignItems={'center'} overflow={'hidden'}>
        <TeamAvatar src={logoUrl} />

        <Typography variant='body2' component='span' textOverflow={'ellipsis'} overflow={'hidden'} title={name}>
          {name}
        </Typography>
      </Stack>
    </MenuItemContentWrapper>
  );
};
