import { useDashboardEvents } from 'entities/dashboard/hooks/useDashboardEvents';
import { EmbeddedDashboardFeature } from 'features/dashboard/EmbeddedDashboard.feature';
import { SUPERSET_PLAYER_PROFILE_EXPLORE_DASHBOARD_ID } from 'kognia/env';
import { DashboardPlaylistGenericPayload } from 'shared/types/embeddedDashboard';
import { Player } from 'shared/types/player/player';
import { invariant } from 'shared/utils/invariant';

type Props = {
  player: Player;
  seasonYear?: number;
  competitionId?: string;
  onEvent: (payload: DashboardPlaylistGenericPayload) => void;
};

export const PlayerProfileDashboardWidget = ({ player, competitionId, seasonYear, onEvent }: Props) => {
  const season = player.playerDefaults?.season;
  invariant(SUPERSET_PLAYER_PROFILE_EXPLORE_DASHBOARD_ID, 'Player profile not found due to local configuration issue');
  invariant(season);
  invariant(season.competition);

  useDashboardEvents({ onEvent: onEvent });

  return (
    <EmbeddedDashboardFeature
      dashboardId={SUPERSET_PLAYER_PROFILE_EXPLORE_DASHBOARD_ID}
      params={{
        player_uuid: player.id,
        competition_uuid: competitionId,
        season_year: seasonYear,
      }}
      key={SUPERSET_PLAYER_PROFILE_EXPLORE_DASHBOARD_ID}
    />
  );
};
