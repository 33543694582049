import { List } from '@mui/material';

import { OrganizationItem } from 'pages/backoffice/features/organizations-list/ui/OrganizationItem';
import { Organization } from 'pages/backoffice/types/organizations';

interface Props {
  organizations: Organization[];
}

export const OrganizationsList = ({ organizations }: Props) => {
  return (
    <List>
      {organizations.map((organization) => (
        <OrganizationItem key={organization.id} organization={organization} />
      ))}
    </List>
  );
};
