import { Alert, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { usePlayerStats } from 'entities/dashboard/api/usePlayerStats';

interface Props {
  playerId: string;
  competitionId: string;
  seasonYear: number;
}

const MINIMUM_MINUTES_PLAYED_PERCENTAGE = 0.1;

export const PlayerAlert = ({ playerId, competitionId, seasonYear }: Props) => {
  const { t } = useTranslation(['player']);
  const { data: playerStats } = usePlayerStats({
    playerId,
    competitionId,
    seasonYear,
  });
  const showCalculationWarning =
    playerStats && playerStats.totalMinutes < playerStats.totalTeamMinutes * MINIMUM_MINUTES_PLAYED_PERCENTAGE;

  if (!showCalculationWarning) return null;

  return (
    <Stack alignItems={'flex-start'} gap={2}>
      <Alert severity={'warning'} variant={'standard'}>
        {t('alert.minimum-minutes-played')}
      </Alert>
    </Stack>
  );
};
