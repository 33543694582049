import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { IconExport } from 'kognia-ui/icons/IconExport';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { XML_GROUP_BY, useDownloadAnalysisXml } from 'api/tactical-analysis/use-download-analysis-xml';
import { DialogNew } from 'shared/components/dialog-new';

type ExportXMLModalProps = {
  recordingId: string;
  tacticalAnalysisId?: string;
  onClose: () => void;
};

const TIMELINE_TYPE = {
  FULL_MATCH: 'full-match',
  EFFECTIVE_TIME: 'effective-time',
};

export const ExportXMLModal = ({ recordingId, tacticalAnalysisId, onClose }: ExportXMLModalProps) => {
  const { t } = useTranslation();
  const [timelineTypeValue, setTimelineTypeValue] = useState(TIMELINE_TYPE.FULL_MATCH);
  const [groupByValue, setGroupByValue] = useState(XML_GROUP_BY.TEAM);
  const downloadAnalysisXml = useDownloadAnalysisXml(
    recordingId,
    tacticalAnalysisId,
    timelineTypeValue === TIMELINE_TYPE.EFFECTIVE_TIME,
    groupByValue,
  );

  const handleTimelineTypeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setTimelineTypeValue((event.target as HTMLInputElement).value);
  }, []);
  const handleGroupByChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setGroupByValue((event.target as HTMLInputElement).value);
  }, []);

  const handleOnSubmit = useCallback(() => {
    downloadAnalysisXml().finally(onClose);
  }, [downloadAnalysisXml, onClose]);

  return (
    <DialogNew
      open={true}
      onClose={onClose}
      title={t('timeline:actions.export.xml.export-xml')}
      icon={<IconExport size='small' />}
      maxWidth='sm'
      fullWidth
      onCancel={onClose}
      onSubmit={handleOnSubmit}
      buttonSubmitText={t('timeline:actions.export.title')}
    >
      <Box>
        <Box marginBottom={2}>
          <FormControl>
            <RadioGroup
              aria-labelledby='timeline-type'
              name='timeline-type'
              value={timelineTypeValue}
              onChange={handleTimelineTypeChange}
            >
              <FormControlLabel
                value={TIMELINE_TYPE.FULL_MATCH}
                control={<Radio color='primary' />}
                label={
                  <Typography fontSize={fontSizes.default}>
                    {t('timeline:actions.export.xml.export-full-match-xml')}
                  </Typography>
                }
              />
              <FormControlLabel
                value={TIMELINE_TYPE.EFFECTIVE_TIME}
                control={<Radio color='primary' />}
                label={
                  <Typography fontSize={fontSizes.default}>
                    {t('timeline:actions.export.xml.export-effective-time-xml')}
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box>
          <Typography fontWeight={'fontWeightMedium'} fontSize={fontSizes.small} textTransform={'uppercase'}>
            {t('timeline:actions.export.xml.group-by')}
          </Typography>
          <FormControl>
            <RadioGroup aria-labelledby='group-by' name='group-by' value={groupByValue} onChange={handleGroupByChange}>
              <FormControlLabel
                value={XML_GROUP_BY.TEAM}
                control={<Radio color='primary' />}
                label={
                  <Typography fontSize={fontSizes.default}>{t('timeline:actions.export.xml.group-by-team')}</Typography>
                }
              />
              <FormControlLabel
                value={XML_GROUP_BY.PLAYER}
                control={<Radio color='primary' />}
                label={
                  <Typography fontSize={fontSizes.default}>
                    {t('timeline:actions.export.xml.group-by-player')}
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
    </DialogNew>
  );
};
