import { zodResolver } from '@hookform/resolvers/zod';
import { IconEdit } from 'kognia-ui/icons/IconEdit';
import { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { useInvalidateTeams } from 'pages/backoffice/api/teams/use-teams';
import { useUpdateTeam } from 'pages/backoffice/api/teams/use-update-team';
import { editTeamFormSchema } from 'pages/backoffice/pages/teams/ui/team-form/form';
import { TeamForm } from 'pages/backoffice/pages/teams/ui/team-form/TeamForm';
import { EditTeamForm, EditTeamFormSchema, TeamFormFieldsNames } from 'pages/backoffice/pages/teams/ui/team-form/types';
import { DialogNew } from 'shared/components/dialog-new';
import { Team } from 'shared/types/team/team';

interface Props {
  open: boolean;
  onClose: () => void;
  teamId: string;
  team?: Team;
}

export const EditTeamModal = ({ open, onClose, team, teamId }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const invalidateQuery = useInvalidateTeams();
  const { editTeam } = useUpdateTeam();

  const initialValues = team
    ? {
        [TeamFormFieldsNames.name]: team.name,
        [TeamFormFieldsNames.abbreviation]: team.abbreviation,
        [TeamFormFieldsNames.countryCode]: team.countryCode,
        [TeamFormFieldsNames.teamType]: team.teamType,
      }
    : undefined;

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    watch,
    trigger,
    control,
  } = useForm<EditTeamFormSchema>({
    resolver: zodResolver(editTeamFormSchema),
    defaultValues: initialValues,
  });

  const handleEditTeam = useCallback(
    (team: EditTeamForm) => {
      editTeam({
        data: {
          name: team[TeamFormFieldsNames.name],
          country_code: team[TeamFormFieldsNames.countryCode],
          team_type: team[TeamFormFieldsNames.teamType],
          abbreviation: team[TeamFormFieldsNames.abbreviation],
          image: team[TeamFormFieldsNames.teamImage] ? team[TeamFormFieldsNames.teamImage][0] : undefined,
        },
        id: teamId,
        onSuccess: () => {
          invalidateQuery();
          onClose();
        },
      });
    },
    [editTeam, invalidateQuery, onClose, teamId],
  );

  const handleModalSubmit = useCallback(() => {
    formRef.current?.requestSubmit();
  }, []);

  return (
    <DialogNew
      maxWidth='sm'
      fullWidth
      title='Edit Team'
      icon={<IconEdit size='small' />}
      onCancel={onClose}
      onClose={onClose}
      onSubmit={handleModalSubmit}
      buttonSubmitText={'Done'}
      open={open}
      submitDisabled={!isDirty}
    >
      <form ref={formRef} onSubmit={handleSubmit(handleEditTeam)}>
        <TeamForm<EditTeamFormSchema>
          watch={watch}
          register={register}
          errors={errors}
          trigger={trigger}
          control={control}
        />
      </form>
    </DialogNew>
  );
};
