import { Box, ListItem } from '@mui/material';
import { Colors } from 'kognia-ui';
import React from 'react';

export const RenderSelectOption = (
  props: React.ComponentProps<typeof ListItem> & { name: React.ReactNode | string },
) => {
  return (
    <ListItem {...props} sx={{ '&:hover': { background: Colors.background } }}>
      <Box px={1} py={0.5} width={'100%'}>
        {props.name}
      </Box>
    </ListItem>
  );
};
