import { MenuItem, styled } from '@mui/material';

type Props = {
  hideBorder?: boolean;
};

export const DashboardMenuItem = styled(MenuItem, { shouldForwardProp: (prop) => prop !== 'hideBorder' })<Props>(
  ({ hideBorder, theme }) => ({
    height: '40px',
    borderBottom: hideBorder ? '1px solid transparent' : `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: `1px solid ${theme.palette.common.white}`,
    },
  }),
);
