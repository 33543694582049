import { Box, Stack, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { PlayerAvatar } from 'kognia-ui/components/avatar/PlayerAvatar';
import { TeamAvatar } from 'kognia-ui/components/avatar/TeamAvatar';
import { IconBall } from 'kognia-ui/icons/IconBall';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { optimisticFavoritesUpdatePlayerWithDefaults } from 'api/player/usePlayerWithDefaults';
import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { useUpdatePlayerFavorites } from 'entities/dashboard/api/useUpdatePlayerFavorites';
import { getPlayerPositionFromValue } from 'entities/dashboard/types/dashboard';
import { routes } from 'kognia/router/routes';
import { ButtonOutlined } from 'shared/components/button-outlined/ButtonOutlined';
import { FavoriteStarButton } from 'shared/components/FavoriteStarButton';
import { ProfileInfoWrapper } from 'shared/components/ProfileInfoWrapper';
import { useFeatureFlag } from 'shared/contexts/app-state/hooks/useFeatureFlag';
import { ActionTypes } from 'shared/streams/actionTypes';
import { publishEvent } from 'shared/streams/eventEmitter';
import { MetricsNames } from 'shared/types/metrics';
import { Player } from 'shared/types/player/player';
import { getPlayerValueFromFixtures } from 'widgets/dashboard/player-profile-header/utils/getPlayerValueFromFixtures';

interface Props {
  player: Player;
}

export const PlayerProfileInfo = ({ player }: Props) => {
  const { t } = useTranslation(['player', 'common']);
  const favoritesFeatureFlag = useFeatureFlag(FEATURE_FLAG.APP_FAVORITES);
  const { updatePlayerFavorites } = useUpdatePlayerFavorites();
  const navigate = useNavigate();

  const handleNavigateToTeam = (teamId: string) => {
    navigate(generatePath(routes.TEAM_PROFILE, { teamId }));
  };

  const playerPosition = getPlayerValueFromFixtures(player, 'position');
  const playerJerseyNumber = getPlayerValueFromFixtures(player, 'jerseyNumber');

  const handleFavoritesButtonClick = useCallback(() => {
    publishEvent({
      type: ActionTypes.UPDATE_FAVORITES,
      payload: {
        name: MetricsNames.FAVORITES_UPDATE,
        data: {
          action: !player.isFavorite ? 'add' : 'remove',
          place: 'page',
          type: 'player',
        },
      },
    });
    updatePlayerFavorites({
      playerId: player.id,
      isFavorite: !player.isFavorite,
      onMutate: optimisticFavoritesUpdatePlayerWithDefaults,
    });
  }, [player.id, player.isFavorite, updatePlayerFavorites]);

  return (
    <ProfileInfoWrapper>
      <Stack direction={'row'} gap={2} alignItems={'center'}>
        <PlayerAvatar withBorder size={'xlarge'} src={player.photoUrl} />
        <Stack gap={1}>
          <Typography fontSize={fontSizes.medium} fontWeight={'fontWeightBold'} pl={0.5}>
            {Boolean(playerJerseyNumber) && `${playerJerseyNumber}. `}
            {player.name}
          </Typography>
          {player.playerDefaults?.team ? (
            <ButtonOutlined
              sx={{ px: 3, maxWidth: 'fit-content' }}
              startIcon={<TeamAvatar src={player.playerDefaults.team.logoUrl} />}
              onClick={() => handleNavigateToTeam(player.playerDefaults!.team.id)}
            >
              <Typography component='span' variant='caption' fontWeight='fontWeightMedium'>
                {player.playerDefaults.team.name}
              </Typography>
            </ButtonOutlined>
          ) : null}

          {Boolean(playerPosition) && (
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <IconBall size={'small'} />
              <Typography fontSize={fontSizes.default}>
                {t(`position.${getPlayerPositionFromValue(playerPosition)}`)}
              </Typography>
            </Stack>
          )}
        </Stack>
        {favoritesFeatureFlag ? (
          <Box sx={{ position: 'absolute', top: (theme) => theme.spacing(0.5), right: (theme) => theme.spacing(0.5) }}>
            <FavoriteStarButton
              isFavorite={player.isFavorite}
              onClick={handleFavoritesButtonClick}
              title={t(player.isFavorite ? 'favorites.remove' : 'favorites.add', { ns: 'common' })}
            />
          </Box>
        ) : null}
      </Stack>
    </ProfileInfoWrapper>
  );
};
