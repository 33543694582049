import { useCallback, useRef } from 'react';

import { useTeamsInfinityQuery } from 'api/teams/use-teams-infinity-query/useTeamsInfinityQuery';
import { useCompetitionsState, useSetCompetitionsState } from 'entities/competitions/store/steps';
import { TeamRow } from 'features/competitions/teams-step/ui/team-row/TeamRow';
import { DashboardMenuItem } from 'shared/components/DashboardMenuItem';
import { DashboardMenuList } from 'shared/components/DashboardMenuList';
import { useIntersectionObserver } from 'shared/hooks/use-intersection-observer/useIntersectionObserver';
import { Team } from 'shared/types/team/team';
import { invariant } from 'shared/utils/invariant';

export const TeamsStepFeature = () => {
  const { season } = useCompetitionsState();
  const setCompetitionsState = useSetCompetitionsState();
  invariant(season?.id, 'seasonId is undefined in TeamsStepFeature');
  const { items, isLoading, fetchNextPage, hasNextPage } = useTeamsInfinityQuery({
    initialFilters: { seasonId: season?.id },
  });
  const lastTeamRef = useRef<HTMLLIElement | null>(null);

  const handleObserver = useCallback(() => {
    if (!hasNextPage || isLoading) {
      return;
    }

    fetchNextPage();
  }, [fetchNextPage, hasNextPage, isLoading]);

  useIntersectionObserver(handleObserver, lastTeamRef);

  const handleGotToPlayers = (team: Team) => {
    setCompetitionsState((prev) => {
      return {
        ...prev,
        step: 'players',
        team,
      };
    });
  };

  return (
    <>
      {items.length > 0 && (
        <DashboardMenuList>
          {items.map((team, index) => (
            <DashboardMenuItem
              key={team.id}
              ref={index === items.length - 1 ? lastTeamRef : null}
              onClick={() => handleGotToPlayers(team)}
            >
              <TeamRow logoUrl={team.logoUrl} name={team.name} />
            </DashboardMenuItem>
          ))}
        </DashboardMenuList>
      )}
    </>
  );
};
