import { Box, Paper, Stack, Tab, Tabs, Typography } from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PlayerFavoritesListFeature } from 'features/dashboard/PlayerFavoritesList.feature';
import { TeamFavoritesListFeature } from 'features/dashboard/TeamFavoritesList.feature';
import { TabPanel } from 'shared/components/tabs/components/tab-panel/TabPanel';

function a11yProps(index: number) {
  return {
    id: `favorite-tab-${index}`,
    'aria-controls': `favorite-tabpanel-${index}`,
  };
}

export const FavoritesCardWidget = () => {
  const [value, setValue] = useState(0);
  const { t } = useTranslation(['common']);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper elevation={0} sx={{ height: '100%', minHeight: '100%', overflow: 'hidden' }}>
      <Stack px={2} py={3} gap={1} width='100%' height={'100%'}>
        <Typography component='h3' variant='body2' fontWeight='fontWeightMedium' px={1}>
          {t('favorites.title')}
        </Typography>

        <Stack gap={2} flexGrow={1} overflow={'hidden'}>
          <Box>
            <Tabs value={value} onChange={handleChange} aria-label='favorites tabs' sx={{ flex: 1 }}>
              <Tab label={t('players')} {...a11yProps(0)} />
              <Tab label={t('teams')} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel
            index={0}
            value={value}
            sx={{
              flexGrow: 1,
              overflow: 'hidden',
            }}
          >
            <PlayerFavoritesListFeature />
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            sx={{
              flexGrow: 1,
              overflow: 'hidden',
            }}
          >
            <TeamFavoritesListFeature />
          </TabPanel>
        </Stack>
      </Stack>
    </Paper>
  );
};
