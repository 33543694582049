import { Stack, Typography } from '@mui/material';
import { PlayerAvatar } from 'kognia-ui/components/avatar/PlayerAvatar';

interface Props {
  name: string;
  photoUrl: string | null;
}

export const NameRow = ({ name, photoUrl }: Props) => {
  return (
    <Stack width='100%' direction='row' alignItems='center' gap={1} flex={1} overflow={'hidden'}>
      <PlayerAvatar src={photoUrl} size='xsmall' withBorder />

      <Typography variant='body2' component='span' textOverflow={'ellipsis'} overflow={'hidden'} title={name}>
        {name}
      </Typography>
    </Stack>
  );
};
