import { useFetchAccount } from 'api/user/use-fetch-account';
import { User } from 'shared/types/user/types';

export function useUser(): User {
  const { data: user } = useFetchAccount();

  if (!user) {
    throw new Error('User is not authenticated');
  }

  return user;
}
