import { Box, MenuList, MenuListProps } from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';

export const DashboardMenuList = forwardRef(
  (props: Exclude<MenuListProps, 'component' | 'sx'>, ref: ForwardedRef<HTMLUListElement>) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          height: '100%',
          px: 0,
        }}
      >
        <MenuList ref={ref} sx={{ py: 0, overflowY: 'auto', scrollBehavior: 'smooth', pr: 1 }} {...props} />
      </Box>
    );
  },
);

DashboardMenuList.displayName = 'DashboardMenuList';
