import { Stack, styled } from '@mui/material';

export const MenuItemContentWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  display: 'grid',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));
