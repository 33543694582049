import { useMemo } from 'react';

import { useClientIdValue } from 'shared/contexts/app-state/hooks/useClientIdValue';
import { useUser } from 'shared/contexts/app-state/hooks/useUser';
import { Client } from 'shared/types/user/types';
import { findClientOrThrow } from 'shared/utils/findClientOrThrow';

export const useCurrentClient = (): Client => {
  const clientId = useClientIdValue();
  const user = useUser();

  return useMemo(() => findClientOrThrow(user.clients, clientId), [user, clientId]);
};
