import { Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import Toggle from 'shared/components/toggle';
import {
  EPISODES_PLAYING_MODE,
  getPlayingMode,
  TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE,
} from 'shared/components/video-player/defaultPlayingModes';
import { useCurrentPlaylistItem, useVideoPlayerPlayingMode } from 'shared/components/video-player/hooks';
import { PlayingMode } from 'shared/components/video-player/types';
import { VideoBarBlock } from 'shared/components/video-player/video-player-bar/video-bar-block';
import { OverlaysQualitySelector } from 'shared/components/video-player/video-player-bar/video-bar-tactic-drawings-mode/overlays-quality-selector';
import { useFeatureFlag } from 'shared/contexts/app-state/hooks/useFeatureFlag';

interface Props {
  showTacticDrawingsOnly: boolean;
  disabledTacticDrawings?: boolean;
  setPlayingMode: (playingMode: PlayingMode) => void;
}

const useVideoPlayerChangePlayingMode = (setPlayingMode: (playingMode: PlayingMode) => void) => {
  const videoPlayerPlayingMode = useVideoPlayerPlayingMode();
  const playlistItem = useCurrentPlaylistItem();

  return useCallback(() => {
    if (playlistItem.hasHomographies) {
      return setPlayingMode(
        getPlayingMode({
          playingMode: videoPlayerPlayingMode,
          showOverlays: !videoPlayerPlayingMode.useEffectiveTime,
          useEffectiveTime: !videoPlayerPlayingMode.useEffectiveTime,
        }),
      );
    }

    setPlayingMode(
      !videoPlayerPlayingMode.useEffectiveTime ? EPISODES_PLAYING_MODE : TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE,
    );
  }, [playlistItem.hasHomographies, setPlayingMode, videoPlayerPlayingMode]);
};

export const VideoBarTacticDrawingsMode = ({
  showTacticDrawingsOnly,
  disabledTacticDrawings,
  setPlayingMode,
}: Props) => {
  const isOverlaysQualityDropDownAvailable = useFeatureFlag(FEATURE_FLAG.CUSTOM_OVERLAYS_QUALITY_DROPDOWN);
  const { t } = useTranslation();
  const videoPlayerPlayingMode = useVideoPlayerPlayingMode();
  const handleTacticDrawingsSwitch = useVideoPlayerChangePlayingMode(setPlayingMode);
  const currentPlaylistItem = useCurrentPlaylistItem();

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.altKey && (event.code === 'KeyO' || event.code === 'keyo')) {
        event.preventDefault();
        event.stopPropagation();
        handleTacticDrawingsSwitch();
      }
    },
    [handleTacticDrawingsSwitch],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const showOverlaysQualityDropDown = Boolean(
    isOverlaysQualityDropDownAvailable && currentPlaylistItem.hasHomographies && currentPlaylistItem.showOverlays,
  );

  return (
    <VideoBarBlock hideSeparator={showTacticDrawingsOnly}>
      <Typography fontSize={fontSizes.small}>{t('common:tactic-drawings')}</Typography>
      <Toggle
        disabled={disabledTacticDrawings !== undefined ? disabledTacticDrawings : false}
        checked={videoPlayerPlayingMode.showOverlays}
        onClick={handleTacticDrawingsSwitch}
      />
      {showOverlaysQualityDropDown ? <OverlaysQualitySelector /> : null}
    </VideoBarBlock>
  );
};
