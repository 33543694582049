import { useCallback } from 'react';

import { generateVideoSourcesFromVideoTypes } from 'entities/playlist/hooks/use-map-videos/utils/generateVideoSourcesFromVideoTypes';
import { PlaylistItemType, VideoSourceType } from 'shared/components/video-player/types';
import { Playlist } from 'shared/types';
import { getVideoSources } from 'shared/utils/get-video-sources';

export const useMapVideos = () => {
  return useCallback((playlist: Playlist): PlaylistItemType[] => {
    return playlist.playlistItems.map((playlistItem) => {
      const playlistItemRecording = playlist.recordings.find((recording) => recording.id === playlistItem.recordingId);
      const videoSources = getVideoSources(playlistItemRecording?.videoSources || []);
      const videoTypes: VideoSourceType[] = generateVideoSourcesFromVideoTypes({
        startTime: playlistItem.startTime,
        endTime: playlistItem.endTime,
        episodesVideos: playlistItem.episodesVideos,
        videoSources,
        useCustomOverlays: playlistItem.hasHomographies,
      });

      return {
        id: playlistItem.id,
        name: playlistItem.name,
        index: playlistItem.index,
        duration: playlistItem.endTime - playlistItem.startTime,
        videoTypes,
        recordingName: playlistItemRecording?.name,
        recordingMatchday: playlistItemRecording?.matchDay,
        recordingId: playlistItem.recordingId,
        hasHomographies: playlistItem.hasHomographies,
        fundamentalsSelected: playlistItem.fundamentalsSelected,
        showOverlays: playlistItem.showOverlays,
      };
    });
  }, []);
};
