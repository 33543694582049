import { Stack, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { PlayerAvatar } from 'kognia-ui/components/avatar/PlayerAvatar';
import { TeamAvatar } from 'kognia-ui/components/avatar/TeamAvatar';
import { MouseEvent, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { optimisticFavoritesUpdatePlayerWithDefaults } from 'api/player/usePlayerWithDefaults';
import { usePlayerInfinityQuery } from 'entities/dashboard/api/use-player-infinity-query/usePlayerInfinityQuery';
import { useUpdatePlayerFavorites } from 'entities/dashboard/api/useUpdatePlayerFavorites';
import { routes } from 'kognia/router/routes';
import { DashboardMenuItem } from 'shared/components/DashboardMenuItem';
import { DashboardMenuList } from 'shared/components/DashboardMenuList';
import { FavoriteStarButton } from 'shared/components/FavoriteStarButton';
import { MenuItemContentWrapper } from 'shared/components/MenuItemContentWrapper';
import Spinner from 'shared/components/spinner';
import { useIntersectionObserver } from 'shared/hooks/use-intersection-observer/useIntersectionObserver';
import { ActionTypes } from 'shared/streams/actionTypes';
import { publishEvent } from 'shared/streams/eventEmitter';
import { MetricsNames } from 'shared/types/metrics';
import { Player } from 'shared/types/player/player';

const getTeamFromPlayer = (player: Player) => {
  return player.fixtures[0].team;
};

export const PlayerFavoritesListFeature = () => {
  const lastItemRef = useRef<HTMLLIElement | null>(null);
  const { items, hasNextPage, isLoading, fetchNextPage } = usePlayerInfinityQuery({
    initialFilters: { isFavorite: true },
  });
  const { updatePlayerFavorites } = useUpdatePlayerFavorites();
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);

  const handleMenuItemClick = useCallback(
    (playerId: string) => {
      publishEvent({
        type: ActionTypes.NAVIGATE_TO_FAVORITE,
        payload: {
          name: MetricsNames.FAVORITE_NAVIGATE_TO,
          data: {
            type: 'player',
          },
        },
      });
      navigate(generatePath(routes.PLAYER_PROFILE, { playerId }));
    },
    [navigate],
  );

  const handleFavoritesButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>, player: Player) => {
      event.stopPropagation();
      publishEvent({
        type: ActionTypes.UPDATE_FAVORITES,
        payload: {
          name: MetricsNames.FAVORITES_UPDATE,
          data: {
            action: !player.isFavorite ? 'add' : 'remove',
            place: 'card',
            type: 'player',
          },
        },
      });
      updatePlayerFavorites({
        playerId: player.id,
        isFavorite: !player.isFavorite,
        onMutate: optimisticFavoritesUpdatePlayerWithDefaults,
      });
    },
    [updatePlayerFavorites],
  );

  const handleObserver = useCallback(() => {
    if (!hasNextPage || isLoading) {
      return;
    }

    fetchNextPage();
  }, [fetchNextPage, hasNextPage, isLoading]);

  useIntersectionObserver(handleObserver, lastItemRef);

  if (isLoading) {
    return (
      <Stack justifyContent={'center'} alignItems={'center'} pl={2} py={2}>
        <Spinner />
      </Stack>
    );
  }

  if (!items.length) {
    return (
      <Stack justifyContent={'center'} pl={2}>
        <Typography variant={'caption'} fontWeight={'fontWeightMedium'}>
          {t('favorites.no-player-favorites-results')}
        </Typography>
      </Stack>
    );
  }

  return (
    <DashboardMenuList>
      {items.map((item, index) => {
        const playerTeam = getTeamFromPlayer(item);

        return (
          <DashboardMenuItem
            ref={index === items.length - 1 ? lastItemRef : null}
            key={item.id}
            onClick={() => handleMenuItemClick(item.id)}
            tabIndex={0}
          >
            <MenuItemContentWrapper gridTemplateColumns={'1fr 1fr auto'}>
              <Stack direction={'row'} alignItems={'center'} gap={1} overflow={'hidden'}>
                <PlayerAvatar withBorder size={'xsmall'} src={item.photoUrl} />
                <Typography fontSize={fontSizes.default} textOverflow={'ellipsis'} overflow={'hidden'}>
                  {item.name}
                </Typography>
              </Stack>
              <Stack alignItems={'center'} direction={'row'} gap={1} overflow={'hidden'}>
                <TeamAvatar src={playerTeam.logoUrl} size={'xsmall'} />
                <Typography fontSize={fontSizes.small} textOverflow={'ellipsis'} overflow={'hidden'}>
                  {playerTeam.name}
                </Typography>
              </Stack>
              <Stack>
                <FavoriteStarButton
                  title={t(item.isFavorite ? 'favorites.remove' : 'favorites.add')}
                  isFavorite={item.isFavorite}
                  onClick={(event) => handleFavoritesButtonClick(event, item)}
                />
              </Stack>
            </MenuItemContentWrapper>
          </DashboardMenuItem>
        );
      })}
    </DashboardMenuList>
  );
};
