import { PLAYER_ACTIONS } from 'shared/components/video-player/state/states';
import { Send } from 'shared/components/video-player/state/types';
import { PlayingMode, PlaylistItemType } from 'shared/components/video-player/types';

export const createActions = (send: Send) => {
  return {
    reorder: (currentVideoIndex: number, newVideoIndex: number) => {
      send({
        type: PLAYER_ACTIONS.REORDER_PLAYLIST_ITEM,
        currentVideoIndex,
        newVideoIndex,
      });
    },
    toggleFullScreen: () => send({ type: PLAYER_ACTIONS.TOGGLE_FULL_SCREEN }),
    changePlayingMode: (playingMode: PlayingMode, tryToKeepCurrentTime = true, autoplay = false) =>
      send({
        type: PLAYER_ACTIONS.CHANGE_PLAYING_MODE,
        playingMode,
        tryToKeepCurrentTime,
        autoplay,
      }),
    jumpToMatchTime: (time: number) => send({ type: PLAYER_ACTIONS.JUMP_TO_MATCH_TIME, time }),
    jumpToTimePercent: (percent: number) => {
      send({ type: PLAYER_ACTIONS.JUMP_TO_TIME_PERCENT, percent });
    },
    loadPlaylist: (playlistItems: PlaylistItemType[], initialStartTime = 0) =>
      send({ type: PLAYER_ACTIONS.LOAD_PLAYLIST, playlistItems, initialStartTime }),
    restart: () => send({ type: PLAYER_ACTIONS.RESTART }),
    refresh: () => send({ type: PLAYER_ACTIONS.REFRESH }),
    setPlaylist: (
      playlistItems: PlaylistItemType[],
      playingMode: PlayingMode,
      autoplay?: boolean,
      tryToKeepCurrentTime?: boolean,
    ) => send({ type: PLAYER_ACTIONS.REPLACE_PLAYLIST, playlistItems, playingMode, tryToKeepCurrentTime, autoplay }),
    pause: () => send({ type: PLAYER_ACTIONS.PAUSE }),
    play: () => send({ type: PLAYER_ACTIONS.PLAY }),
    replacePlaylistItems: (playlistItems: PlaylistItemType[]) => {
      send({ type: PLAYER_ACTIONS.REPLACE_PLAYLIST_ITEMS, playlistItems });
    },
    updatePlaylistItems: (playlistItems: PlaylistItemType[]) => {
      send({ type: PLAYER_ACTIONS.UPDATE_PLAYLIST_ITEMS, playlistItems });
    },
    togglePlaying: () => send({ type: PLAYER_ACTIONS.TOGGLE_PLAYING }),
    setPlaylistItem: (id: string, autoPlay: boolean) =>
      send({
        type: PLAYER_ACTIONS.LOAD_PLAYLIST_ITEM,
        playlistItemId: id,
        autoPlay,
      }),
    removePlaylistItem: (id: string) => send({ type: PLAYER_ACTIONS.REMOVE_PLAYLIST_ITEM, playlistItemId: id }),
    removePlaylistItems: (playlistItemsIds: string[]) =>
      send({ type: PLAYER_ACTIONS.REMOVE_PLAYLIST_ITEMS, playlistItemsIds }),
    updatePlaylistItem: (playlistItem: PlaylistItemType, currentTime?: number) =>
      send({ type: PLAYER_ACTIONS.UPDATE_PLAYLIST_ITEM, playlistItem, currentTime }),
    skipForward5s: () => send({ type: PLAYER_ACTIONS.SKIP_FORWARD }),
    skipBackward5s: () => send({ type: PLAYER_ACTIONS.SKIP_BACKWARD }),
    nextPlaylistItem: () => send({ type: PLAYER_ACTIONS.NEXT_PLAYLIST_ITEM }),
    autoplayNextPlaylistItem: () => send({ type: PLAYER_ACTIONS.AUTOPLAY_NEXT_PLAYLIST_ITEM }),
    previousPlaylistItem: () => send({ type: PLAYER_ACTIONS.PREVIOUS_PLAYLIST_ITEM }),
    nextVideoSource: () => send({ type: PLAYER_ACTIONS.NEXT_VIDEO_SOURCE }),
    previousVideoSource: () => send({ type: PLAYER_ACTIONS.PREVIOUS_VIDEO_SOURCE }),
    replay: () => send({ type: PLAYER_ACTIONS.REPLAY }),
    resumeStandBy: () => send({ type: PLAYER_ACTIONS.RESUME_STAND_BY }),
    handleStandBy: () => send({ type: PLAYER_ACTIONS.STAND_BY }),
  };
};
