import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import { useTeamWithDefaults } from 'entities/dashboard/api/useTeamWithDefaults';
import { TeamProfileHeaderFeature } from 'features/dashboard/team-profile-header/TeamProfileHeader.feature';
import { TeamCompetitionsFeature } from 'features/dashboard/TeamCompetitions.feature';
import { Dialog } from 'shared/components/dialog';
import { useTemporaryPlaylist } from 'shared/hooks/useTemporaryPlaylist';
import { DashboardPlaylistGenericPayload } from 'shared/types/embeddedDashboard';
import { TeamProfileDashboardWidget } from 'widgets/dashboard/TeamProfileDashboard.widget';
import { TemporaryPlaylistWidget } from 'widgets/playlist/temporary-playlist/TemporaryPlaylist.widget';

type Props = {
  teamId: string;
};

export const TeamProfile = ({ teamId }: Props) => {
  const { data: team } = useTeamWithDefaults(teamId);

  const [selectedSeasonYear, setSelectedSeasonYear] = useState<number | undefined>();
  const [selectedCompetition, setSelectedCompetition] = useState<string | undefined>();

  const [playlist, setPlaylist] = useState<DashboardPlaylistGenericPayload | undefined>(undefined);
  const { data: temporaryPlaylist } = useTemporaryPlaylist({ playlist });

  useEffect(() => {
    if (!team) return;

    setSelectedSeasonYear(team.teamDefaults?.season?.year);
    setSelectedCompetition(team.teamDefaults?.season?.competition?.id);
  }, [team]);

  if (!team) return null;

  return (
    <>
      <Stack gap={2} padding={(theme) => theme.spacing(1.5, 4, 2, 4)}>
        <TeamProfileHeaderFeature />

        <TeamCompetitionsFeature
          selectedSeasonYear={selectedSeasonYear}
          selectedCompetition={selectedCompetition}
          onChangeSelectedCompetition={setSelectedCompetition}
          onChangeSelectedSeasonYear={setSelectedSeasonYear}
        />
      </Stack>

      {selectedCompetition && selectedSeasonYear && (
        <>
          <TeamProfileDashboardWidget
            competitionId={selectedCompetition}
            key={`${selectedCompetition}-${selectedSeasonYear}`}
            onEvent={setPlaylist}
            seasonYear={selectedSeasonYear}
            team={team}
          />
          <Dialog onClose={() => setPlaylist(undefined)} open={!!temporaryPlaylist} fullWidth maxWidth={false}>
            <Box
              height={(theme) => `calc(100vh - ${theme.spacing(8)})`}
              width={(theme) => `calc(100vw - ${theme.spacing(8)})`}
            >
              {temporaryPlaylist && <TemporaryPlaylistWidget playlist={temporaryPlaylist} />}
            </Box>
          </Dialog>
        </>
      )}
    </>
  );
};
