import { UseQueryResult } from '@tanstack/react-query';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { transformOrganizations } from 'pages/backoffice/api/organizations/transformer';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { Organization } from 'pages/backoffice/types/organizations';

type Params = {
  id: string | undefined;
  onSuccess?: (competition: Organization) => void;
};

export const useOrganization = ({ id, onSuccess }: Params): UseQueryResult<Organization> => {
  return useFetchRequest<Organization>({
    queryRef: ['metadata-organization', id ?? ''],
    url: `${annotationApiUrls.ORGANIZATIONS}/${id ?? ''}`,
    transformer: (organization) => transformOrganizations([organization])[0],
    options: {
      enabled: Boolean(id),
    },
    onSuccess,
  });
};
