import { IconButton, Tooltip } from '@mui/material';
import { IconStar } from 'kognia-ui/icons/IconStar';
import { IconStarFilled } from 'kognia-ui/icons/IconStarFilled';
import { MouseEvent } from 'react';

type Props = {
  isFavorite: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  title: string;
};

export const FavoriteStarButton = ({ isFavorite, onClick, title }: Props) => {
  return (
    <Tooltip title={title}>
      <IconButton size={'small'} onClick={onClick}>
        {isFavorite ? <IconStarFilled size={'small'} color={'primary'} /> : <IconStar size={'small'} />}
      </IconButton>
    </Tooltip>
  );
};
