import { Box, Stack, styled, SxProps, Theme } from '@mui/material';
import { forwardRef } from 'react';

interface Props {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
  sx?: SxProps<Theme>;
}

export const TabPanelContainer = styled(Stack)(() => ({
  flexDirection: 'column',
}));

export const TabPanel = forwardRef((props: Props, ref: any) => {
  const { children, value, index, ...other } = props;

  if (value !== index) return null;

  return (
    <TabPanelContainer ref={ref} role='tabpanel' hidden={value !== index} {...other}>
      {children}
    </TabPanelContainer>
  );
});

TabPanel.displayName = 'TabPanel';

export const TabsPanel = styled(Box)(({ theme }) => ({ overflowY: 'scroll', marginTop: theme.spacing(2) }));
