import { Box, Tooltip } from '@mui/material';
import { IconWorld } from 'kognia-ui/icons/IconWorld';

import { CountryFlag } from 'shared/components/CountryFlag';
import { Countries } from 'shared/utils/countries';

type Props = {
  countryCode: string;
};

export const CompetitionCountry = ({ countryCode }: Props) => {
  const countryData = Countries.find((item) => item.code.toLocaleLowerCase() === countryCode.toLocaleLowerCase());

  if (!countryData) return <IconWorld size='small' color='info' />;

  return (
    <Tooltip title={countryData.name}>
      <Box>
        <CountryFlag countryCode={countryData.code} />
      </Box>
    </Tooltip>
  );
};
