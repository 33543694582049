import { Box } from '@mui/material';
import { forwardRef, PropsWithChildren, RefObject } from 'react';

import { RowHeadersResizeBar } from 'pages/tactical-analysis/components/timeline/timeline-table/components/row-headers-resize-bar';
import { useTacticalAnalysisAppliedFilters } from 'pages/tactical-analysis/hooks/use-tactical-analysis-applied-filters';
import { useTimelineTableData } from 'pages/tactical-analysis/hooks/use-timeline-table-data';

export const RowsHeaders = forwardRef(
  (
    {
      children,
      recordingId,
      headerRef,
      visibleContentRef,
      ...rest
    }: PropsWithChildren<
      React.HTMLAttributes<HTMLDivElement> & {
        recordingId: string;
        headerRef: RefObject<HTMLDivElement>;
        visibleContentRef: RefObject<HTMLDivElement>;
      }
    >,
    ref: any,
  ) => {
    const timelineTableData = useTimelineTableData(recordingId);
    const { areFiltersApplied } = useTacticalAnalysisAppliedFilters(recordingId);

    const showHeaders =
      timelineTableData.rowGroups.filter((r) => r.totalClips > 0).length > 0 ||
      (areFiltersApplied && timelineTableData?.filtersRow && timelineTableData.filtersRow.clips.length > 0);

    if (!showHeaders) return null;

    return (
      <>
        <Box ref={ref} position='relative' zIndex={1} pb={4} bgcolor='common.white' overflow='hidden' {...rest}>
          {children}
        </Box>

        <RowHeadersResizeBar headerContentDiv={headerRef} contentDiv={visibleContentRef} />
      </>
    );
  },
);

RowsHeaders.displayName = 'RowsHeaders';
