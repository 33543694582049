import { UseQueryResult } from '@tanstack/react-query';
import { useState } from 'react';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { FeatureFlagsUrl } from 'api/routes';
import { FeatureFlags } from 'shared/types';

type UseFetchAccountResponse = Omit<UseQueryResult<FeatureFlags>, 'data'> & {
  data: FeatureFlags | undefined;
  setEnabled: (enabled: boolean) => void;
};

export const transformFeatureFlags = (featureFlags: FeatureFlags): FeatureFlags => {
  return featureFlags;
};

export enum FEATURE_FLAG {
  APP_HOTJAR = 'app-hotjar',
  APP_DASHBOARDS = 'app-dashboards',
  APP_DASHBOARD_PREVIEW = 'app-dashboard-preview',
  APP_EXPLORE = 'app-explore',
  APP_EXPLORE_HOME_PAGE = 'app-explore-home-page',
  APP_FAVORITES = 'app-favorites',
  CUSTOM_OVERLAYS_QUALITY_DROPDOWN = 'app-custom-overlays-quality-dropdown',
}

const FEATURE_FLAGS_VALUES = {
  [FEATURE_FLAG.APP_DASHBOARDS]: true,
  [FEATURE_FLAG.APP_DASHBOARD_PREVIEW]: true,
  [FEATURE_FLAG.APP_EXPLORE]: true,
  [FEATURE_FLAG.APP_EXPLORE_HOME_PAGE]: true,
  [FEATURE_FLAG.APP_FAVORITES]: true,
  [FEATURE_FLAG.CUSTOM_OVERLAYS_QUALITY_DROPDOWN]: true,
  [FEATURE_FLAG.APP_HOTJAR]: true,
};

const LOCALHOST_HOSTNAME = 'localhost';

export const FEATURE_FLAGS_QUERY_KEY = 'fetchFeatureFlags';

export const useFetchFeatureFlags = (): UseFetchAccountResponse => {
  const [enabled, setEnabled] = useState(false);
  const fetchRequest = useFetchRequest<FeatureFlags>({
    queryRef: [FEATURE_FLAGS_QUERY_KEY],
    url: FeatureFlagsUrl,
    transformer: transformFeatureFlags,
    options: { enabled },
  });

  const includeLocalhostFeatureFlags = window.location.hostname === LOCALHOST_HOSTNAME;

  const featureFlags =
    includeLocalhostFeatureFlags && fetchRequest.data
      ? { ...FEATURE_FLAGS_VALUES, ...fetchRequest.data }
      : fetchRequest.data;

  return { ...fetchRequest, data: featureFlags, setEnabled };
};
