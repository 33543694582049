import { useDashboardEvents } from 'entities/dashboard/hooks/useDashboardEvents';
import { EmbeddedDashboardFeature } from 'features/dashboard/EmbeddedDashboard.feature';
import { SUPERSET_TEAM_PROFILE_EXPLORE_DASHBOARD_ID } from 'kognia/env';
import { DashboardPlaylistGenericPayload } from 'shared/types/embeddedDashboard';
import { TeamWithPlayers } from 'shared/types/team/team';
import { invariant } from 'shared/utils/invariant';

type Props = {
  team: TeamWithPlayers;
  seasonYear?: number;
  competitionId?: string;
  onEvent: (payload: DashboardPlaylistGenericPayload) => void;
};

export const TeamProfileDashboardWidget = ({ team, competitionId, seasonYear, onEvent }: Props) => {
  const season = team.teamDefaults?.season;
  invariant(SUPERSET_TEAM_PROFILE_EXPLORE_DASHBOARD_ID, 'Team profile not found due to local configuration issue');
  invariant(season);
  invariant(season.competition);

  useDashboardEvents({ onEvent: onEvent });

  return (
    <EmbeddedDashboardFeature
      params={{
        team_uuid: team.id,
        competition_uuid: competitionId,
        season_year: seasonYear,
      }}
      key={SUPERSET_TEAM_PROFILE_EXPLORE_DASHBOARD_ID}
      dashboardId={SUPERSET_TEAM_PROFILE_EXPLORE_DASHBOARD_ID}
    />
  );
};
