import isEqual from 'lodash/isEqual';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTacticalAnalysisEpisodes } from 'pages/tactical-analysis/hooks/use-tactical-analysis-episodes';
import {
  useCurrentPlaylistItem,
  useVideoPlayerActions,
  useVideoPlayerPlayingMode,
} from 'shared/components/video-player/hooks';
import { PlayingMode, PlayingModes } from 'shared/components/video-player/types';
import { VideoBarContent, VideoPlayerBarContainer } from 'shared/components/video-player/video-player-bar/styled';
import { VideoBarHeader } from 'shared/components/video-player/video-player-bar/video-bar-header';
import { VideoSourceSelect } from 'shared/components/video-player/video-player-bar/video-source-select';
import {
  VideoSourceSelectListItem,
  VideoSourceSelectListItemTypes,
  VideoSourceSelectListItemView,
} from 'shared/components/video-player/video-player-bar/video-source-select-list';

interface Props {
  showTacticDrawingsOnly: boolean;
  showTacticDrawings?: boolean;
  disabledTacticDrawings?: boolean;
  setPlayingMode: (playingMode: PlayingMode) => void;
  recordingId: string;
}

export const VideoPlayerBar = ({
  showTacticDrawingsOnly = false,
  showTacticDrawings = true,
  disabledTacticDrawings,
  setPlayingMode,
  recordingId,
}: Props) => {
  const { t } = useTranslation();
  const playingMode = useVideoPlayerPlayingMode();
  const actions = useVideoPlayerActions();
  const currentPlaylistItem = useCurrentPlaylistItem();
  const episodes = useTacticalAnalysisEpisodes(recordingId);

  const shouldShowTacticDrawings = useMemo(() => {
    const hasEpisodes = episodes.length > 0;
    const hasEpisodesSources = hasEpisodes && episodes.some((item) => !!item.dashUrl);

    if ((!currentPlaylistItem.hasHomographies && !hasEpisodesSources) || !currentPlaylistItem.showOverlays) {
      return false;
    }
    if (showTacticDrawings && showTacticDrawingsOnly) return true;
    if (
      playingMode.mode !== PlayingModes.PANORAMIC &&
      showTacticDrawings &&
      currentPlaylistItem.videoTypes.some((item) => item.playingMode.showOverlays) &&
      !currentPlaylistItem.hasHomographies
    )
      return true;

    return false;
  }, [episodes, currentPlaylistItem, showTacticDrawingsOnly, showTacticDrawings, playingMode]);

  const selectableVideoTypes = useMemo(
    () => currentPlaylistItem.videoTypes.filter((item) => item.playingMode.mode !== PlayingModes.EPISODES),
    [currentPlaylistItem],
  );

  const handlePlayingModeChange = useCallback(
    (playingMode: PlayingMode) => {
      actions.changePlayingMode(playingMode);
    },
    [actions],
  );

  const selectOptionsFromVideoSources: VideoSourceSelectListItemView[] = useMemo(
    () =>
      selectableVideoTypes.map((videoType) => {
        return {
          type: VideoSourceSelectListItemTypes.ListItemMode,
          playingMode: videoType.playingMode,
          id:
            videoType.playingMode.mode === PlayingModes.TACTICAL_CAMERA
              ? PlayingModes.PLAYLIST
              : videoType.playingMode.mode,
          label: t(`video-player:playing-modes.${videoType.playingMode.mode}`),
          onClick: () => handlePlayingModeChange(videoType.playingMode),
        };
      }),
    [handlePlayingModeChange, selectableVideoTypes, t],
  );

  const selectOptions: VideoSourceSelectListItem[] = selectOptionsFromVideoSources;

  const selectedOption = (selectOptions.find((option) => {
    return 'playingMode' in option && isEqual(playingMode, option.playingMode);
  }) || selectOptions[0]) as VideoSourceSelectListItemView;

  const isVideoSourceSelectDisabled = selectOptions.length === 1;

  if (showTacticDrawingsOnly && !shouldShowTacticDrawings) return null;

  return (
    <VideoPlayerBarContainer>
      <VideoBarContent>
        {!showTacticDrawingsOnly ? (
          <VideoSourceSelect options={selectOptions} selected={selectedOption} disabled={isVideoSourceSelectDisabled} />
        ) : null}
        <VideoBarHeader
          disabledTacticDrawings={disabledTacticDrawings}
          setPlayingMode={setPlayingMode}
          shouldShowTacticDrawings={shouldShowTacticDrawings}
          showTacticDrawingsOnly={showTacticDrawingsOnly}
        />
      </VideoBarContent>
    </VideoPlayerBarContainer>
  );
};
