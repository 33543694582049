import { Stack } from '@mui/material';
import { RefObject, UIEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchResult, SearchType } from 'entities/dashboard/types/dashboardSearch';
import { SearchResultPaper } from 'entities/dashboard/ui/SearchResultPaper';
import { DashboardSearchResultsTabsFeature } from 'features/dashboard/dashboard-search-results-tabs/DashboardSearchResultsTabs.feature';
import Spinner from 'shared/components/spinner';

type Props = {
  currentOptions: readonly SearchResult[];
  fetchNextPage: () => void;
  isLoading: boolean;
  onEscape: () => void;
  onSelect: (data: SearchResult) => void;
  width: number;
  totals: {
    [SearchType.ALL]: number;
    [SearchType.PLAYER]: number;
    [SearchType.TEAM]: number;
  };
  searchInputRef: RefObject<HTMLInputElement>;
  searchResultsRef: RefObject<HTMLUListElement>;
};

const CONTAINER_SCROLL_PADDING = 8;

export const DashboardSearchResultsWidget = ({
  searchInputRef,
  searchResultsRef,
  currentOptions,
  fetchNextPage,
  isLoading,
  onEscape,
  onSelect,
  totals,
  width,
}: Props) => {
  const { t } = useTranslation('explore');
  const handleOnScroll = useCallback(
    (event: UIEvent<HTMLElement>) => {
      const target = event.currentTarget;
      const isScrollBottom = target.offsetHeight + target.scrollTop + CONTAINER_SCROLL_PADDING >= target.scrollHeight;

      if (isScrollBottom && !isLoading) {
        fetchNextPage();
      }
    },
    [fetchNextPage, isLoading],
  );

  return (
    <SearchResultPaper width={width}>
      {isLoading ? (
        <Stack height={'56px'} justifyContent={'center'} alignItems={'center'}>
          <Spinner />
        </Stack>
      ) : (
        <DashboardSearchResultsTabsFeature
          noResultsMessage={t('input.no-results-found')}
          onEscape={onEscape}
          onScroll={handleOnScroll}
          onSelect={onSelect}
          searchResultsRef={searchResultsRef}
          searchInputRef={searchInputRef}
          searchResults={currentOptions}
          title={t('input.search-results')}
          totals={totals}
        />
      )}
    </SearchResultPaper>
  );
};
