import { zodResolver } from '@hookform/resolvers/zod';
import { IconUser } from 'kognia-ui/icons/IconUser';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useCreateOrganization } from 'pages/backoffice/api/organizations/useCreateOrganization';
import { useUpdateOrganization } from 'pages/backoffice/api/organizations/useUpdateOrganization';
import { organizationFormSchema } from 'pages/backoffice/features/organization-form/constants/organizationForm.constants';
import { OrganizationFormFeature } from 'pages/backoffice/features/organization-form/OrganizationForm.feature';
import {
  OrganizationFormDefaultValues,
  OrganizationFormSchema,
  OrganizationFormValues,
  OrganizationOnSubmit,
} from 'pages/backoffice/features/organization-form/types/organizationForm.types';
import { DialogNew } from 'shared/components/dialog-new';

interface BaseProps {
  open: boolean;
  onClose: () => void;
}

interface CreateProps extends BaseProps {
  isEdit?: undefined;
  defaultValues?: OrganizationFormDefaultValues;
  id?: undefined;
}

interface EditProps extends BaseProps {
  isEdit: true;
  defaultValues: OrganizationFormDefaultValues;
  id: string;
}

type Props = CreateProps | EditProps;

const formId = 'organizations-form';

export const OrganizationFormModalFeature = ({ open, onClose, defaultValues, id, isEdit }: Props) => {
  const form = useForm<OrganizationFormValues>({
    resolver: zodResolver(organizationFormSchema),
    defaultValues,
  });

  const { create, isPending: isPendingCreate } = useCreateOrganization();
  const { update, isPending: isPendingUpdate } = useUpdateOrganization();

  const isLoading = isEdit ? isPendingUpdate : isPendingCreate;

  const handleCreate = useCallback(
    (data: OrganizationFormSchema) => {
      create({
        data,
        onSuccess: () => {
          onClose();
          form.reset();
        },
      });
    },
    [create, form, onClose],
  );

  const handleUpdate = useCallback(
    (data: OrganizationFormSchema) => {
      if (!id) return;

      update({
        data,
        id,
        onSuccess: onClose,
      });
    },
    [id, onClose, update],
  );

  const handleCreateOrUpdate = useCallback(
    (params: OrganizationOnSubmit) => {
      if (defaultValues) {
        handleUpdate(params.data);
      } else {
        handleCreate(params.data);
      }
    },
    [defaultValues, handleCreate, handleUpdate],
  );

  return (
    <DialogNew
      maxWidth='sm'
      fullWidth
      title={defaultValues ? 'Edit organization' : 'New organization'}
      icon={<IconUser size='small' />}
      onCancel={onClose}
      onClose={onClose}
      buttonSubmitText={defaultValues ? 'Save' : 'Create'}
      open={open}
      buttonFormId={formId}
      isLoading={isLoading}
      submitDisabled={!form.formState.isDirty}
    >
      <OrganizationFormFeature onSubmit={handleCreateOrUpdate} formId={formId} form={form} />
    </DialogNew>
  );
};
