import { Stack, Typography } from '@mui/material';
import { CompetitionAvatar } from 'kognia-ui/components/avatar/CompetitionAvatar';

import { CompetitionCountry } from 'features/competitions/competitions-step/ui/CompetitionCountry';
import { MenuItemContentWrapper } from 'shared/components/MenuItemContentWrapper';

interface Props {
  name: string;
  countryCode: string | null;
  logoUrl?: string | null;
}

export const CompetitionRow = ({ countryCode, name, logoUrl }: Props) => {
  return (
    <MenuItemContentWrapper gridTemplateColumns={'1fr auto'}>
      <Stack direction='row' alignItems='center' gap={1} overflow={'hidden'}>
        <CompetitionAvatar src={logoUrl} />

        <Typography variant='body2' textOverflow={'ellipsis'} overflow={'hidden'} title={name}>
          {name}
        </Typography>
      </Stack>

      {countryCode && <CompetitionCountry countryCode={countryCode} />}
    </MenuItemContentWrapper>
  );
};
