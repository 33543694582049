import z from 'zod';

export enum OrganizationFormFieldsNames {
  name = 'name',
  image = 'image',
}

export const OrganizationFormLabels = {
  [OrganizationFormFieldsNames.name]: 'Name',
  [OrganizationFormFieldsNames.image]: 'Image',
};

export const organizationFormSchema = z.object({
  [OrganizationFormFieldsNames.name]: z.string().trim().min(3),
  [OrganizationFormFieldsNames.image]: z.instanceof(FileList).optional(),
});
