import {
  Avatar,
  FormControl,
  FormHelperText,
  Input,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';
import React, { useMemo } from 'react';
import { Control, Controller, FieldErrors, Path, UseFormRegister, UseFormTrigger, UseFormWatch } from 'react-hook-form';

import { TeamFormFieldsNames } from 'pages/backoffice/pages/teams/ui/team-form/types';
import { FormFormLabel } from 'shared/components/form/form-form-label';
import { TeamTypes } from 'shared/types/team/team';
import { Countries } from 'shared/utils/countries';

interface Props<TFormValues extends Record<string, any>> {
  register: UseFormRegister<TFormValues>;
  errors: FieldErrors<TFormValues>;
  watch: UseFormWatch<TFormValues>;
  trigger: UseFormTrigger<TFormValues>;
  control: Control<TFormValues>;
}

export function TeamForm<TFormValues extends Record<string, any>>({
  register,
  errors,
  watch,
  trigger,
  control,
}: Props<TFormValues>) {
  const teamLogo = watch<Path<TFormValues>>(TeamFormFieldsNames.teamImage as Path<TFormValues>);
  const logoUrlValue = teamLogo && teamLogo.length > 0 ? URL.createObjectURL(teamLogo[0]) : '';

  const teamTypes = useMemo(
    () =>
      Object.values(TeamTypes).map((teamType) => (
        <MenuItem key={teamType} value={teamType}>
          {teamType}
        </MenuItem>
      )),
    [],
  );

  const countryCodes = useMemo(
    () =>
      Countries.map((country) => (
        <MenuItem key={country.code.toLowerCase()} value={country.code.toUpperCase()}>
          {country.name}
        </MenuItem>
      )),
    [],
  );

  return (
    <>
      <FormControl fullWidth margin='normal'>
        <FormFormLabel>Team Name</FormFormLabel>
        <TextField
          size='small'
          {...register(TeamFormFieldsNames.name as Path<TFormValues>)}
          error={!!errors[TeamFormFieldsNames.name]}
          helperText={errors[TeamFormFieldsNames.name] && <>{errors[TeamFormFieldsNames.name].message}</>}
        />
      </FormControl>
      <FormControl
        fullWidth
        margin='normal'
        onChange={() => trigger(TeamFormFieldsNames.teamImage as Path<TFormValues>)}
      >
        <FormFormLabel>Logo URL</FormFormLabel>
        <Input
          type='file'
          size='small'
          {...register(TeamFormFieldsNames.teamImage as Path<TFormValues>)}
          error={!!errors[TeamFormFieldsNames.teamImage]}
        />
        {errors[TeamFormFieldsNames.teamImage] && (
          <Typography color={Colors.red}>
            <>{errors[TeamFormFieldsNames.teamImage].message ?? ''}</>
          </Typography>
        )}
        <Stack justifyContent={'center'} spacing={1} marginTop={1} alignItems={'center'}>
          <Typography fontSize={fontSizes.small}>Logo preview</Typography>
          <Avatar src={logoUrlValue} />
        </Stack>
      </FormControl>

      <Controller
        control={control}
        name={TeamFormFieldsNames.teamType as Path<TFormValues>}
        render={({ field: { onChange, value, name } }) => (
          <FormControl fullWidth>
            <FormFormLabel>Team type</FormFormLabel>
            <Select size={'small'} labelId={`${name}-label`} id={name} value={value} onChange={onChange}>
              {teamTypes}
            </Select>
          </FormControl>
        )}
      />
      <FormControl fullWidth margin='normal'>
        <FormFormLabel>Abbreviation</FormFormLabel>
        <TextField
          size='small'
          {...register(TeamFormFieldsNames.abbreviation as Path<TFormValues>)}
          error={!!errors[TeamFormFieldsNames.abbreviation]}
          helperText={
            errors[TeamFormFieldsNames.abbreviation] && <>{errors[TeamFormFieldsNames.abbreviation].message}</>
          }
        />
      </FormControl>
      <Controller
        control={control}
        name={TeamFormFieldsNames.countryCode as Path<TFormValues>}
        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
          <FormControl error={!!error} fullWidth>
            <FormFormLabel>Country</FormFormLabel>
            <Select size={'small'} labelId={`${name}-label`} id={name} value={value} onChange={onChange} displayEmpty>
              <MenuItem value={''}>No Country</MenuItem>
              {countryCodes}
            </Select>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </FormControl>
        )}
      />
    </>
  );
}
