import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useMatchedRoutePattern } from 'shared/hooks/useMatchedRoutePattern';
import { ActionTypes } from 'shared/streams/actionTypes';
import { publishEvent } from 'shared/streams/eventEmitter';
import { MetricsNames } from 'shared/types/metrics';

export const ProtectedRouteInner = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const routePattern = useMatchedRoutePattern();

  useEffect(() => {
    publishEvent({
      type: ActionTypes.VIEW_PAGE,
      payload: {
        name: MetricsNames.PAGE_VIEW,
        data: { generic_url: routePattern ?? '', url: location.pathname },
      },
    });
  }, [location.pathname, routePattern]);

  return <>{children}</>;
};
